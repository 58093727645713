<template>
  <div class="flex flex-col min-h-screen">
    <header class="flex flex-col w-full max-md:max-w-full">
      <OnboardingNavbar :authStore="authStore" />
    </header>
    <main
      class="flex z-10 flex-col items-center pt-20 mt-0 w-full bg-white rounded-md shadow-[0px_-17px_16px_rgba(0,0,0,0.1)] max-md:max-w-full"
    >
      <section class="flex flex-col justify-center max-w-full text-center">
        <h1
          class="self-center text-3xl font-semibold leading-tight text-zinc-800 max-md:max-w-full"
        >
          <p v-if="invited">You have been invited to Zoominfo</p>
          <p v-else>Welcome to SlashExperts</p>
        </h1>
        <p class="mt-3 text-base text-neutral-700 max-md:max-w-full">
          Fill in the information to get started
        </p>
      </section>
      <section>
        <div
          class="flex flex-col mt-12 sm:flex-row items-center justify-center gap-4 sm:gap-2 md:gap-3 w-full"
        >
          <!-- First Block -->
          <div class="relative flex items-center space-x-2">
            <!-- Circle with Check Icon Centered -->
            <div
              class="w-5 h-5 bg-primary-purple rounded-full flex items-center justify-center"
            >
              <!-- Font Awesome Check Icon -->
              <i class="fas fa-check text-white text-xs"></i>
            </div>
            <div class="ml-2 text-center whitespace-nowrap">
              Profile Information
            </div>
          </div>

          <!-- First Line -->
          <div class="hidden sm:block w-16 h-px bg-gray-300"></div>

          <!-- Second Block -->
          <div class="flex items-center space-x-2">
            <div
              class="w-5 h-5 bg-primary-purple rounded-full flex items-center justify-center"
            >
              <!-- Font Awesome Check Icon -->
              <i class="fas fa-check text-white text-xs"></i>
            </div>
            <div class="text-center whitespace-nowrap text-bg-300">
              Software Expertise
            </div>
          </div>

          <!-- Second Line -->
          <div class="hidden sm:block w-16 h-px bg-gray-300"></div>

          <!-- Third Block -->
          <div class="flex items-center space-x-2">
            <div class="flex-shrink-0 w-2 h-2 bg-violet-600 rounded-full"></div>
            <div class="text-center whitespace-nowrap text-violet-600">
              Your Calendar
            </div>
          </div>
        </div>
      </section>
      <div
        v-if="errorMessage"
        role="alert"
        class="flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
        style="max-height: 80px; overflow-y: auto"
      >
        <!-- Icon Container -->
        <div
          class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
        >
          <!-- Custom Image Icon -->
          <img
            src="../../assets/shared/failure.svg"
            alt="Error Icon"
            class="w-4 h-4"
          />
        </div>
        <p>{{ errorMessage }}</p>
      </div>
      <SelectCalendarSection
        v-if="
          !checkCalendarSyncedStorage &&
          !googleCalendarSync &&
          !outlookCalendarSync
        "
      />
      <div v-else class="mb-5">
        <section class="flex flex-col my-2">
          <div class="relative flex flex-col sm:flex-row items-center gap-2">
            <!-- Label -->
            <label
              for="timezone-select"
              class="text-sm font-medium whitespace-nowrap"
            >
              Select Timezone:
            </label>

            <!-- Searchable Dropdown -->
            <div class="relative w-full">
              <div class="relative">
                <!-- Dropdown Trigger -->
                <div
                  class="p-2 border border-gray-300 rounded-md text-sm cursor-pointer overflow-x-auto"
                  @click="toggleDropdown"
                >
                  <span>{{
                    selectedTimezone.alternateName || "Select Timezone"
                  }}</span>
                </div>

                <!-- Dropdown -->
                <div
                  v-if="isDropdownOpen"
                  class="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-md w-full"
                >
                  <!-- Search Input -->
                  <input
                    type="text"
                    v-model="searchQuery"
                    placeholder="Search timezones..."
                    class="p-2 border-b border-gray-200 text-sm w-full"
                  />

                  <!-- Options -->
                  <ul class="max-h-40 overflow-auto">
                    <li
                      v-for="option in filteredOptions"
                      :key="option.name"
                      @click="selectTimezone(option)"
                      class="p-2 hover:bg-gray-100 cursor-pointer"
                    >
                      {{ option.alternateName }}
                    </li>
                    <li
                      v-if="filteredOptions.length === 0"
                      class="p-2 text-gray-500"
                    >
                      No options found
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SetAvailability
          :initialSchedule="availabilitySchedule"
          @updateSchedule="handleScheduleUpdate"
          @recheckAvailability="recheckAvailability"
        />
      </div>
    </main>
    <footer
      class="flex flex-wrap justify-center px-2 mt-auto items-center py-4 w-full text-base font-medium whitespace-nowrap border-t border-gray-300 border-solid"
    >
      <div class="flex w-full sm:w-1/2 gap-4 justify-center items-center">
        <BaseButton
          class="flex overflow-hidden w-full sm:w-1/5 gap-3 justify-center items-center py-2.5 px-4 text-indigo-700 bg-indigo-50 rounded-md shadow-sm"
          @click="goBack"
          :disabled="isDisabled"
        >
          <span class="self-stretch my-auto">
            <i class="fas fa-arrow-left shrink-0 w-5 aspect-square"></i>
            Back
          </span></BaseButton
        >
        <BaseButton
          type="button"
          class="flex overflow-hidden w-full sm:w-4/5 gap-3 justify-center items-center py-2.5 px-4 text-white bg-violet-600 rounded-md shadow-sm"
          @click="createAvailabilities"
          :disabled="isDisabled"
        >
          <span v-if="!isDisabled" class="self-stretch my-auto">
            Next
            <i class="fas fa-arrow-right shrink-0 w-5 aspect-square"></i>
          </span>
          <span v-else class="self-stretch my-auto">
            Submitting availabilities.....</span
          >
        </BaseButton>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, getCurrentInstance, ref } from "vue";
import { useRoute } from "vue-router";
import moment from "moment-timezone";
import SelectCalendarSection from "@/components/onboarding/SelectCalendarSection.vue";
import SetAvailability from "@/components/onboarding/SetAvailability.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import OnboardingNavbar from "@/components/onboarding/OnboardingNavbar.vue";
import type { WeekSchedule, Option } from "@/types/onboarding/types";
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { AvailabilityParamItem } from "@/types/onboarding/types";
import { processedTimezones } from "@/utils/timezones";
import router from "@/router";
import { PostHog } from "posthog-js";
import { useAuthStore } from "@/stores/auth";
import {
  HOME_ICON,
  MUSICAL_NOTES_ICON,
  TRIANGLE_ERROR_ICON,
} from "@/assets/svg/shared/svgConstants";

const CREATE_AVAILABILITY = gql`
  mutation CreateAvailibility(
    $availibilityParams: [AvailibilityInput!]!
    $onboardingStep: String
  ) {
    createAvailibility(
      input: {
        availibilityParams: $availibilityParams
        onboardingStep: $onboardingStep
      }
    ) {
      message
      success
      errors
    }
  }
`;

export default defineComponent({
  name: "CalendarView",
  components: {
    SelectCalendarSection,
    SetAvailability,
    BaseButton,
    OnboardingNavbar,
  },
  setup() {
    const invited = ref("");
    const errorMessage = ref("");
    const isDisabled = ref(false);
    const availabilitySchedule = ref<WeekSchedule>({
      Monday: [{ startTime: "09:00 AM", endTime: "05:00 PM", note: "" }],
      Tuesday: [{ startTime: "09:00 AM", endTime: "05:00 PM", note: "" }],
      Wednesday: [{ startTime: "09:00 AM", endTime: "05:00 PM", note: "" }],
      Thursday: [{ startTime: "09:00 AM", endTime: "05:00 PM", note: "" }],
      Friday: [{ startTime: "09:00 AM", endTime: "05:00 PM", note: "" }],
      Saturday: [{ startTime: "09:00 AM", endTime: "05:00 PM", note: "" }],
      Sunday: [{ startTime: "09:00 AM", endTime: "05:00 PM", note: "" }],
    });
    const route = useRoute();
    const authStore = useAuthStore();
    const posthog = (getCurrentInstance()?.proxy as { $posthog: PostHog })
      ?.$posthog;
    const timezoneOptions = ref<Option[]>(processedTimezones());
    const selectedTimezone = ref<Option>(timezoneOptions.value[0]);
    const searchQuery = ref("");
    const isDropdownOpen = ref(false);

    const googleCalendarSync = computed(() => {
      const value = route.query.google_calendar_sync as string | undefined;
      if (value !== undefined && value.toLowerCase() === "true") {
        authStore.setCalendarSynced("google");
      }
      return value !== undefined ? value.toLowerCase() === "true" : false;
    });

    const outlookCalendarSync = computed(() => {
      const value = route.query.outlook_calendar_sync as string | undefined;
      if (value !== undefined && value.toLowerCase() === "true") {
        authStore.setCalendarSynced("outlook");
      }
      return value !== undefined ? value.toLowerCase() === "true" : false;
    });

    const handleError = () => {
      errorMessage.value = "Something went wrong";
      isDisabled.value = false;
    };

    const {
      mutate: CreateAvailability,
      onDone,
      onError,
    } = useMutation(CREATE_AVAILABILITY);

    const createAvailabilities = () => {
      errorMessage.value = "";
      isDisabled.value = true;
      let { valid, payload } = validateAvailabilityParams();

      if (!valid || !calendarsynced()) {
        isDisabled.value = false;
        return;
      }

      if (payload.length < 1) {
        errorMessage.value = "Please set at least one availability";
        isDisabled.value = false;
        return;
      }

      CreateAvailability({
        availibilityParams: payload,
        onboardingStep: "completed",
      });

      onDone((response) => {
        if (response) {
          if (response?.errors) {
            handleError();
          } else {
            if (response?.data?.createAvailibility?.success) {
              isDisabled.value = false;
              if (posthog) {
                posthog.capture("Expert_User_Adds_Calendar_Availability", {
                  email: authStore.email,
                });
                posthog.capture("Expert_User_Completed_Entire_Signup", {
                  email: authStore.email,
                });
              }
              router.push({ name: "ExpertExpertise" });
            } else {
              handleError();
            }
          }
        }
      });

      onError((mutationError: ApolloError) => {
        isDisabled.value = false;
        errorMessage.value = `Failed to create availabilities due to: ${mutationError.message}`;
        console.error("Profile update failed:", mutationError.message);
        console.error("GraphQL Errors:", mutationError.graphQLErrors);
        console.error("Network Error:", mutationError.networkError);
      });
    };

    const checkCalendarSyncedStorage = computed(() => {
      return (
        authStore.calendarSynced === "google" ||
        authStore.calendarSynced === "outlook"
      );
    });

    const calendarsynced = () => {
      if (
        authStore.calendarSynced === "google" ||
        authStore.calendarSynced === "outlook"
      ) {
        return true;
      }
      const googleCalendarSync = route.query.google_calendar_sync as
        | string
        | undefined;
      const outlookCalendarSync = route.query.outlook_calendar_sync as
        | string
        | undefined;
      if (googleCalendarSync === "true" || outlookCalendarSync === "true") {
        return true;
      } else {
        errorMessage.value = "Please sync your calendar first";
        return false;
      }
    };

    const validateAvailabilityParams = () => {
      const timeFormat = "hh:mm A";
      let valid = true;
      let payload: AvailabilityParamItem[] = [];
      let errorMessage = "Start time must be less than end time";
      let errorContent = `
          ${TRIANGLE_ERROR_ICON}${errorMessage}
        `;

      for (const [day, entries] of Object.entries(availabilitySchedule.value)) {
        entries.forEach((entry, index) => {
          if (entry.startTime != "00:00") {
            let start = moment(entry.startTime, timeFormat);
            let end = moment(entry.endTime, timeFormat);
            let label = document.querySelector(
              `#${day}-${index}`
            ) as HTMLLabelElement | null;
            if (start.isSameOrAfter(end)) {
              setInvalidTimeIntervalError(label, errorContent);
              valid = false;
            } else {
              setInvalidTimeIntervalError(label, "");
            }
            if (valid) {
              payload.push({
                day: day,
                startTime: moment(entry.startTime, timeFormat).format("HH:mm"),
                endTime: moment(entry.endTime, timeFormat).format("HH:mm"),
                note: entry.note,
                timezone: selectedTimezone.value.name,
              });
            }
          }
        });
      }
      return { valid, payload };
    };

    const setInvalidTimeIntervalError = (
      label: HTMLLabelElement | null,
      message: string
    ): void => {
      if (label) {
        label.innerHTML = message;
      } else {
        console.log("Label not found");
      }
    };

    const goBack = () => {
      router.push({ name: "OnboardingSoftwareExpertise" });
    };

    const handleScheduleUpdate = (newSchedule: WeekSchedule) => {
      errorMessage.value = "";
      availabilitySchedule.value = newSchedule;
      validateAvailabilityParams();
    };

    const recheckAvailability = () => {
      setTimeout(() => {
        validateAvailabilityParams();
      }, 100);
    };

    const filteredOptions = computed(() =>
      timezoneOptions.value.filter((option) =>
        option.alternateName
          .toLowerCase()
          .includes(searchQuery.value.toLowerCase())
      )
    );

    const toggleDropdown = () => {
      isDropdownOpen.value = !isDropdownOpen.value;
    };

    const selectTimezone = (option: Option) => {
      selectedTimezone.value = option;
      isDropdownOpen.value = false; // Close dropdown after selection
    };

    return {
      invited,
      googleCalendarSync,
      createAvailabilities,
      availabilitySchedule,
      handleScheduleUpdate,
      outlookCalendarSync,
      timezoneOptions,
      selectedTimezone,
      errorMessage,
      recheckAvailability,
      goBack,
      authStore,
      isDisabled,
      HOME_ICON,
      MUSICAL_NOTES_ICON,
      calendarsynced,
      checkCalendarSyncedStorage,
      searchQuery,
      filteredOptions,
      toggleDropdown,
      selectTimezone,
      isDropdownOpen,
    };
  },
});
</script>
