<template>
  <div class="flex flex-col bg-white">
    <header class="flex flex-col w-full max-md:max-w-full">
      <OnboardingNavbar :auth-store="authStore" />
    </header>
    <main
      class="flex z-10 flex-col items-center pt-10 mt-0 w-full bg-white rounded-md shadow-[0px_-17px_16px_rgba(0,0,0,0.1)] max-md:max-w-full"
    >
      <section class="flex flex-col justify-center max-w-full text-center">
        <h1
          class="self-center text-3xl font-semibold leading-tight text-zinc-800 max-md:max-w-full"
        >
          <p v-if="authStore.joinProgram">
            You have been invited to {{ authStore.joinProgram?.name }}
          </p>
          <p v-else>Welcome to SlashExperts</p>
        </h1>
        <p class="mt-3 text-base text-neutral-700 max-md:max-w-full">
          Fill in the information to get started
        </p>
      </section>
      <section>
        <div
          class="flex flex-col mt-12 sm:flex-row items-center justify-center gap-4 sm:gap-2 md:gap-3 w-full"
        >
          <!-- First Block -->
          <div class="relative flex items-center space-x-2">
            <!-- Circle with Check Icon Centered -->
            <div class="flex-shrink-0 w-2 h-2 bg-violet-600 rounded-full"></div>
            <div class="ml-2 text-center whitespace-nowrap text-violet-600">
              Profile Information
            </div>
          </div>

          <!-- First Line -->
          <div class="hidden sm:block w-16 h-px bg-gray-300"></div>

          <!-- Second Block -->
          <div class="flex items-center space-x-2">
            <div class="flex-shrink-0 w-2 h-2 bg-gray-300 rounded-full"></div>
            <div class="text-center whitespace-nowrap">Software Expertise</div>
          </div>

          <!-- Second Line -->
          <div class="hidden sm:block w-16 h-px bg-gray-300"></div>

          <!-- Third Block -->
          <div class="flex items-center space-x-2">
            <div class="flex-shrink-0 w-2 h-2 bg-gray-300 rounded-full"></div>
            <div class="text-center whitespace-nowrap">Your Calendar</div>
          </div>
        </div>
      </section>
      <div
        v-if="errorMessage"
        role="alert"
        class="flex gap-2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
      >
        <!-- Icon Container -->
        <div
          class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
        >
          <!-- Custom Image Icon -->
          <img
            src="../../assets/shared/failure.svg"
            alt="Error Icon"
            class="w-4 h-4"
          />
        </div>
        <p>{{ errorMessage }}</p>
      </div>
      <form
        @submit.prevent="handleSubmit"
        class="flex flex-col mt-2 w-full gap-3 justify-center items-center mx-auto p-4 text-sm leading-none"
      >
        <!-- Linked URL Field -->
        <div class="relative flex flex-col w-1/2 text-zinc-800">
          <BaseLabel
            forAttr="linkedinURL"
            text="Linkedin URL"
            class="font-medium text-left"
          />
          <div class="relative">
            <input
              id="linkedinUrl"
              type="text"
              v-model="linkedinUrl"
              placeholder="https://www.linkedin.com/in/johndoe_32"
              class="px-3.5 py-2.5 mt-1 w-full bg-white rounded-md shadow-sm pr-1 border border-gray-300 border-solid"
            />
          </div>
        </div>
        <div class="flex justify-start w-1/2">
          <div
            class="flex flex-col items-center justify-center border rounded-lg py-2 px-4"
            :class="{
              'border-gray-400': !linkedinUrl,
              'border-violet-600': linkedinUrl,
            }"
          >
            <BaseButton
              v-if="!isFetching"
              type="button"
              @click="fetchLinkedinData"
              class="flex flex-col py-1 px-2 text-xs items-start font-medium leading-none bg-transparent"
              :class="{
                'text-gray-400': !linkedinUrl,
                'text-violet-600': linkedinUrl,
              }"
              :disabled="submitDisabled || !linkedinUrl"
            >
              Fill in below details from
              <span class="block mt-1">my LinkedIn profile</span>
            </BaseButton>
            <div
              v-else
              class="flex flex-col flex items-center gap-2"
              aria-label="Loading"
            >
              <span class="text-xs font-medium text-violet-600"
                >Fetching Details</span
              >
              <div
                class="w-6 h-6 border-4 border-t-4 border-violet-600 border-solid rounded-full spinner"
              ></div>
            </div>
          </div>
        </div>

        <!-- Country and State/Province Fields -->
        <div class="flex flex-col w-1/2 md:flex-row gap-6 mb-4">
          <!-- Country Selector -->
          <div class="flex flex-col flex-grow w-full sm:w-1/2">
            <BaseLabel
              forAttr="country"
              text="Country"
              class="font-medium text-zinc-800 text-left"
              :required="true"
            />
            <div class="relative w-full">
              <div class="relative">
                <!-- Dropdown Trigger -->
                <div
                  class="p-2 border border-gray-300 rounded-md text-sm cursor-pointer"
                  @click="toggleDropdown('country')"
                >
                  <span>{{
                    selectedCountry?.countryName || "Select a country"
                  }}</span>
                </div>

                <!-- Dropdown -->
                <div
                  v-if="isCountryDropdownOpen"
                  class="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-md w-full"
                >
                  <!-- Search Input -->
                  <input
                    type="text"
                    v-model="countrySearchQuery"
                    placeholder="Search countries..."
                    class="p-2 border-b border-gray-200 text-sm w-full"
                  />

                  <!-- Options -->
                  <ul class="max-h-40 overflow-auto">
                    <li
                      v-for="country in filteredCountries"
                      :key="country[1]"
                      @click="
                        selectCountry({
                          countryName: country[0],
                          countrySlug: country[1],
                          regions: country[2].map((region: any) => ({
                            name: region[0],
                            shortCode: region[1],
                          })),
                        })
                      "
                      class="p-2 hover:bg-gray-100 cursor-pointer"
                    >
                      {{ country[0] }}
                    </li>
                    <li
                      v-if="filteredCountries.length === 0"
                      class="p-2 text-gray-500"
                    >
                      No countries found
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- Region Selector -->
          <div class="flex flex-col flex-grow w-full sm:w-1/2">
            <BaseLabel
              forAttr="stateProvince"
              text="State/Province"
              class="font-medium text-zinc-800 text-left"
              :required="true"
            />
            <div class="relative w-full">
              <div class="relative">
                <!-- Dropdown Trigger -->
                <div
                  class="p-2 border border-gray-300 rounded-md text-sm cursor-pointer"
                  :class="{ 'opacity-50': !selectedCountry }"
                  @click="toggleDropdown('region')"
                >
                  <span>{{ selectedRegion?.name || "Select a region" }}</span>
                </div>

                <!-- Dropdown -->
                <div
                  v-if="isRegionDropdownOpen"
                  class="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-md w-full"
                >
                  <!-- Search Input -->
                  <input
                    type="text"
                    v-model="regionSearchQuery"
                    placeholder="Search regions..."
                    class="p-2 border-b border-gray-200 text-sm w-full"
                  />

                  <!-- Options -->
                  <ul class="max-h-40 overflow-auto">
                    <li
                      v-for="region in filteredRegions"
                      :key="region.shortCode"
                      @click="selectRegion(region)"
                      class="p-2 hover:bg-gray-100 cursor-pointer"
                    >
                      {{ region.name }}
                    </li>
                    <li
                      v-if="filteredRegions.length === 0"
                      class="p-2 text-gray-500"
                    >
                      No regions found
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Industry and Profile Picture Fields -->
        <div class="flex flex-col md:flex-row gap-6 mb-4 w-1/2">
          <!-- industry -->
          <div class="flex flex-col flex-grow w-full sm:w-1/2">
            <BaseLabel
              forAttr="industry"
              text="Industry"
              class="font-medium text-zinc-800 text-left"
            />
            <div class="relative w-full">
              <div class="relative">
                <!-- Dropdown Trigger -->
                <div
                  class="p-2 border border-gray-300 rounded-md text-sm cursor-pointer"
                  @click="toggleDropdown('industry')"
                >
                  <span>{{ selectedIndustry || "Select an industry" }}</span>
                </div>

                <!-- Dropdown -->
                <div
                  v-if="isIndustryDropdownOpen"
                  class="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-md w-full"
                >
                  <!-- Search Input -->
                  <input
                    type="text"
                    v-model="industrySearchQuery"
                    placeholder="Search industries..."
                    class="p-2 border-b border-gray-200 text-sm w-full"
                  />

                  <!-- Options -->
                  <ul class="max-h-40 overflow-auto">
                    <li
                      v-for="industry in filteredIndustries"
                      :key="industry"
                      @click="selectIndustry(industry)"
                      class="p-2 hover:bg-gray-100 cursor-pointer"
                    >
                      {{ industry }}
                    </li>
                    <li
                      v-if="filteredIndustries.length === 0"
                      class="p-2 text-gray-500"
                    >
                      No industries found
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- picture -->
          <div class="flex flex-col flex-grow w-full sm:w-1/2">
            <BaseLabel
              v-if="!imagePreview"
              forAttr="profilePicture"
              text="Profile Picture"
              class="font-medium text-zinc-800 text-left"
              :required="true"
            />
            <div class="flex flex-col mt-1 w-full">
              <!-- Display either the upload button or the image preview -->
              <div
                v-if="!imagePreview"
                class="flex justify-center items-center px-10 py-3 w-full rounded-md border-2 text-violet-600 border-gray-300 border-dashed bg-white cursor-pointer"
                @click="handleUploadClick"
              >
                Upload profile pic
              </div>
              <div v-if="imagePreview" class="flex">
                <div class="w-1/3 flex items-center justify-center">
                  <div
                    class="relative w-1/2 pb-[50%] rounded-full overflow-hidden"
                  >
                    <img
                      :src="profilePictureUrl"
                      alt="Profile Preview"
                      class="absolute inset-0 object-cover w-full h-full rounded-full"
                    />
                  </div>
                </div>
                <div class="w-1/3 flex items-center items-center space-x-2">
                  <div
                    class="w-full flex flex-col sm:flex-row items-center justify-between space-y-2 sm:space-y-0 sm:space-x-2"
                  >
                    <BaseButton
                      type="button"
                      class="px-4 py-2 text-xs sm:text-sm font-medium leading-none text-violet-600 bg-transparent text-center sm:text-left"
                      @click="handleChangeClick"
                    >
                      Update
                    </BaseButton>

                    <BaseButton
                      type="button"
                      class="text-black-600 hover:text-red-800 focus:outline-none"
                      @click="handleCloseClick"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M4.8 14.25L3.75 13.2L7.95 9L3.75 4.8L4.8 3.75L9 7.95L13.2 3.75L14.25 4.8L10.05 9L14.25 13.2L13.2 14.25L9 10.05L4.8 14.25Z"
                          fill="#49454F"
                        />
                      </svg>
                    </BaseButton>
                  </div>
                </div>
              </div>
              <!-- Hidden file input -->
              <input
                ref="fileInput"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                class="hidden"
                @change="handleFileUpload"
              />
            </div>
          </div>
        </div>

        <!-- languages -->
        <div class="relative flex flex-col w-1/2 gap-1 text-zinc-800 mb-4">
          <BaseLabel
            forAttr="languages"
            text="Spoken Languages"
            class="font-medium text-left"
            :required="true"
          >
          </BaseLabel>
          <div class="flex flex-col">
            <BaseSearchableDropdown
              v-model="languageToAdd"
              :options="spokenLanguages.map((language) => language)"
              :placeholder="'Select language'"
              class="w-full"
              @change="addLanguage"
            />
          </div>
          <div class="flex flex-wrap gap-2">
            <div
              v-for="language in selectedLanguages"
              :key="language"
              class="px-2 py-1 text-xs sm:text-sm font-medium leading-none text-black border border-gray-300 rounded-md bg-transparent"
            >
              <div class="flex flex-wrap items-center gap-2">
                {{ language }}
                <BaseButton
                  type="button"
                  @click="removeLanguage(language)"
                  class="px-2 text-xs sm:text-sm font-medium leading-none bg-gray-300 text-black border border-gray-300 rounded-full bg-transparent"
                >
                  x
                </BaseButton>
              </div>
            </div>
          </div>
        </div>

        <!-- Title Field -->
        <div class="relative flex flex-col w-1/2 text-zinc-800 mb-4">
          <BaseLabel
            forAttr="title"
            text="Title"
            class="font-medium text-left"
            :required="true"
          />
          <div class="relative">
            <BaseInput
              id="title"
              type="text"
              v-model="title"
              placeholder="Title"
              className="px-3.5 py-2.5 mt-1 w-full bg-white rounded-md shadow-sm border border-gray-300 border-solid"
              required
            />
          </div>
        </div>

        <!-- Company Field -->
        <div class="relative flex flex-col w-1/2 text-zinc-800 mb-4">
          <BaseLabel
            forAttr="company"
            text="Company"
            class="font-medium text-left"
            :required="true"
          />
          <div class="relative">
            <BaseInput
              id="company"
              type="text"
              v-model="company"
              placeholder="Company"
              className="px-3.5 py-2.5 mt-1 w-full bg-white rounded-md shadow-sm border border-gray-300 border-solid"
              required
            />
          </div>
        </div>

        <!-- Next Button -->
        <div
          class="flex justify-center items-center grow shrink py-4 text-base border-t border-gray-300 border-solid font-medium text-white whitespace-nowrap w-full bg-white"
        >
          <BaseButton
            type="submit"
            class="flex flex-wrap gap-3 justify-center items-center py-2.5 pr-4 pl-4 my-auto bg-violet-600 w-1/2 rounded-md shadow-sm"
            :disabled="submitDisabled || isFetching"
          >
            <span v-if="!submitDisabled" class="self-stretch my-auto"
              >Next
              <i class="fas fa-arrow-right shrink-0 w-5 aspect-square"></i>
            </span>
            <span v-else class="self-stretch my-auto">
              Submitting details.....</span
            >
          </BaseButton>
        </div>
      </form>
    </main>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  nextTick,
  onMounted,
  ref,
  watch,
} from "vue";
import { industries } from "@/data/industries";
import BaseLabel from "@/components/shared/BaseLabel.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { ApolloError, gql } from "@apollo/client/core";
import router from "@/router";
import { useAuthStore } from "@/stores/auth";
import OnboardingNavbar from "@/components/onboarding/OnboardingNavbar.vue";
import BaseInput from "@/components/shared/BaseInput.vue";
import { PostHog } from "posthog-js";
import { spokenLanguages } from "@/data/spoken-languages";
import { allCountries } from "country-region-data";
import BaseSearchableDropdown from "@/components/shared/BaseSearchableDropdown.vue";

const GET_LINKEDIN_PROFILE = gql`
  query GetLinkedinProfile($url: String!) {
    linkedinProfile(url: $url) {
      company
      profilePictureUrl
      country
      state
      industry
      title
      errors
    }
  }
`;

const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation updateUserProfile($input: UpdateInput!) {
    updateUserProfile(input: $input) {
      updateUserResponse {
        user {
          profile_picture
        }
        errors
      }
    }
  }
`;

interface Region {
  name: string;
  shortCode: string;
}

interface Country {
  countryName: string;
  countrySlug: string;
  regions: Region[];
}

interface LinkedinProfile {
  company: string;
  profilePictureUrl: string;
  country: string;
  state: string;
  industry: string;
  title: string;
  errors: string[];
}

export default defineComponent({
  name: "ProfileInformation",
  components: {
    BaseLabel,
    BaseButton,
    BaseInput,
    OnboardingNavbar,
    BaseSearchableDropdown,
  },
  setup() {
    const selectedIndustry = ref("");
    const linkedinUrl = ref("");
    const fileInput = ref<HTMLInputElement | null>(null);
    const imagePreview = ref<string>("");
    const countries = ref(allCountries);
    const regions = ref<Region[]>([]);
    const selectedCountry = ref<Country | null>(null);
    const selectedRegion = ref<Region | null>(null);
    const countrySearchQuery = ref("");
    const regionSearchQuery = ref("");
    const industrySearchQuery = ref("");
    const isCountryDropdownOpen = ref(false);
    const isRegionDropdownOpen = ref(false);
    const isIndustryDropdownOpen = ref(false);
    const errorMessage = ref("");
    const company = ref("");
    const selectedLanguages = ref<string[]>([]);
    const isFetching = ref(false);
    const result = ref<any>(null);
    const submitDisabled = ref(false);
    const title = ref("");
    const languageToAdd = ref("");
    const posthog = (getCurrentInstance()?.proxy as { $posthog: PostHog })
      ?.$posthog;

    const authStore = useAuthStore();

    const {
      mutate: updateUserProfile,
      onDone,
      onError,
    } = useMutation(UPDATE_USER_PROFILE_MUTATION);

    onMounted(() => {
      populateFields();
    });

    const handleSubmit = async () => {
      errorMessage.value = "";
      submitDisabled.value = true;
      if (
        !pictureValid() ||
        !linkedinUrlValid() ||
        !spokenLanguagesValid() ||
        !countryAndRegionValid()
      ) {
        submitDisabled.value = false;
        return;
      }
      updateUserProfile({
        input: {
          profileParams: {
            companyName: company.value,
            linkedinProfile: linkedinUrl.value,
            profilePicture: getCleanBase64(imagePreview.value),
            industry: selectedIndustry.value,
            country: selectedCountry.value?.countryName || "",
            state: selectedRegion.value?.name || "",
            title: title.value,
            spokenLanguages: selectedLanguages.value,
          },
          onboardingStep: "expertise",
        },
      });
    };

    onDone((response) => {
      submitDisabled.value = false;
      result.value = response.data.updateUserProfile.updateUserResponse;
      if (result.value.errors) {
        errorMessage.value = result.value.errors.join(".");
      } else {
        storeProfileDetails(result.value.user);
        if (posthog) {
          posthog.capture("Expert_User_Finished_Profile", {
            email: authStore.email,
          });
        }
        router.push({ name: "OnboardingSoftwareExpertise" });
      }
    });

    onError((mutationError: ApolloError) => {
      submitDisabled.value = false;
      errorMessage.value = "Something went wrong.";
      console.error("Profile update failed:", mutationError);
    });

    const pictureValid = (): boolean => {
      if (!imagePreview.value) {
        errorMessage.value = "Please provide your profile picture.";
        return false;
      }

      return true;
    };

    const linkedinUrlValid = (): boolean => {
      try {
        let url = linkedinUrl.value;
        if (!url) {
          return true;
        }
        url = url.endsWith("/") ? url.slice(0, -1) : url;
        if (!url.toLowerCase().includes("linkedin.com/in/")) {
          errorMessage.value = "Linkedin URL missing linkedin.com/in/";
          return false;
        }

        const parts = url.split("/in/");
        if (parts.length > 1 && /^[a-zA-Z0-9-]+$/.test(parts[1])) {
          return true;
        } else {
          errorMessage.value = "Invalid LinkedIn profile username.";
          return false;
        }
      } catch (error) {
        errorMessage.value = "Invalid LinkedIn URL format.";
        console.log("error is ", error);
        return false;
      }
    };

    const spokenLanguagesValid = (): boolean => {
      if (selectedLanguages.value.length < 1) {
        errorMessage.value = "Please select at least one spoken language.";
        return false;
      }
      return true;
    };

    const countryAndRegionValid = (): boolean => {
      if (!selectedCountry.value?.countryName || !selectedRegion.value?.name) {
        errorMessage.value = "Please select a country and region.";
        return false;
      }
      return true;
    };

    const storeProfileDetails = (response: Record<string, string>) => {
      authStore.setProfileInformation({
        linkedinUrl: linkedinUrl.value,
        country: selectedCountry.value?.countryName || "",
        state: selectedRegion.value?.name || "",
        industry: selectedIndustry.value,
        profilePicture: response.profile_picture,
        title: title.value,
        companyName: company.value,
        spokenLanguages: selectedLanguages.value,
      });
    };

    const getCleanBase64 = (base64String: string) => {
      return base64String.replace(/^data:image\/(jpeg|jpg|png);base64,/, "");
    };

    const fetchLinkedinData = () => {
      isFetching.value = true;
      errorMessage.value = "";

      const {
        result,
        loading,
        error: queryError,
      } = useQuery(GET_LINKEDIN_PROFILE, {
        url: linkedinUrl.value,
      });

      watch(
        () => result.value,
        (newValue) => {
          if (newValue) {
            checkLinkedinData(newValue);
          }
        },
        { immediate: true }
      );

      watch(
        () => loading.value,
        (newLoading) => {
          if (!newLoading) {
            stopLoader();
          }
        },
        { immediate: true }
      );

      watch(
        () => queryError.value,
        (newError) => {
          if (newError) {
            errorMessage.value = "Something went wrong.";
          }
        },
        { immediate: true }
      );
    };

    const checkLinkedinData = (result: any) => {
      if (result?.linkedinProfile?.errors?.length > 0) {
        errorMessage.value = result.linkedinProfile.errors.join(". ");
      } else if (result?.linkedinProfile) {
        errorMessage.value = "";
        updateFields(result.linkedinProfile);
      } else {
        errorMessage.value = "Something went wrong, please try again!";
      }
    };

    const updateFields = (linkedinProfile: LinkedinProfile) => {
      selectedIndustry.value = linkedinProfile.industry;
      populateCountryAndRegion(
        linkedinProfile.country,
        linkedinProfile.state,
        "code"
      );

      company.value = linkedinProfile.company;
      title.value = linkedinProfile.title;
      imagePreview.value = linkedinProfile.profilePictureUrl;
    };

    watch(selectedCountry, (newCountry) => {
      if (newCountry && Array.isArray(newCountry.regions)) {
        regions.value = newCountry.regions.map((region: Region) => ({
          name: region.name,
          shortCode: region.shortCode,
        }));
      } else {
        regions.value = [];
      }
      selectedRegion.value = null;
    });

    const toggleDropdown = (type: string) => {
      if (type === "country") {
        isCountryDropdownOpen.value = !isCountryDropdownOpen.value;
        isRegionDropdownOpen.value = false;
        isIndustryDropdownOpen.value = false;
      } else if (type === "region" && selectedCountry.value) {
        isRegionDropdownOpen.value = !isRegionDropdownOpen.value;
        isCountryDropdownOpen.value = false;
        isIndustryDropdownOpen.value = false;
      } else if (type === "industry") {
        isIndustryDropdownOpen.value = !isIndustryDropdownOpen.value;
        isCountryDropdownOpen.value = false;
        isRegionDropdownOpen.value = false;
      }
      clearSearchQueries();
    };

    const clearSearchQueries = () => {
      if (!isCountryDropdownOpen.value) {
        countrySearchQuery.value = "";
      }
      if (!isRegionDropdownOpen.value) {
        regionSearchQuery.value = "";
      }
      if (!isIndustryDropdownOpen.value) {
        industrySearchQuery.value = "";
      }
    };

    const filteredCountries = computed(() =>
      countries.value.filter((c) =>
        c[0].toLowerCase().includes(countrySearchQuery.value.toLowerCase())
      )
    );

    const filteredRegions = computed(() => {
      return regions.value.filter((r) =>
        r?.name?.toLowerCase().includes(regionSearchQuery.value.toLowerCase())
      );
    });

    const filteredIndustries = computed(() => {
      return industries.filter((i) =>
        i.toLowerCase().includes(industrySearchQuery.value.toLowerCase())
      );
    });

    const selectCountry = (country: Country) => {
      selectedCountry.value = country;
      isCountryDropdownOpen.value = false;
      countrySearchQuery.value = "";
    };

    const selectRegion = (region: Region) => {
      selectedRegion.value = region;
      isRegionDropdownOpen.value = false;
      regionSearchQuery.value = "";
    };

    const selectIndustry = (industry: string) => {
      selectedIndustry.value = industry;
      isIndustryDropdownOpen.value = false;
      industrySearchQuery.value = "";
    };

    const findClosestRegion = (
      target: string,
      regions: Region[]
    ): Region | null => {
      if (!target || regions.length === 0) return null;

      let closestRegion: Region | null = null;
      let highestMatchScore = 0;

      regions.forEach((region) => {
        const matchScore = calculateMatchScore(target, region.name);
        if (matchScore > highestMatchScore) {
          highestMatchScore = matchScore;
          closestRegion = region;
        }
      });

      return closestRegion;
    };

    const calculateMatchScore = (target: string, region: string): number => {
      const commonLength = longestCommonSubstring(target, region).length;
      return commonLength;
    };

    const longestCommonSubstring = (s1: string, s2: string): string => {
      let maxLength = 0;
      let endIndex = 0;
      const lengths: number[][] = Array(s1.length + 1)
        .fill(null)
        .map(() => Array(s2.length + 1).fill(0));

      for (let i = 1; i <= s1.length; i++) {
        for (let j = 1; j <= s2.length; j++) {
          if (s1[i - 1] === s2[j - 1]) {
            lengths[i][j] = lengths[i - 1][j - 1] + 1;
            if (lengths[i][j] > maxLength) {
              maxLength = lengths[i][j];
              endIndex = i;
            }
          }
        }
      }

      return s1.substring(endIndex - maxLength, endIndex);
    };

    const stopLoader = () => {
      isFetching.value = false;
    };

    const handleUploadClick = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    const handleFileUpload = (event: Event) => {
      const input = event.target as HTMLInputElement;
      errorMessage.value = "";
      if (input.files && input.files.length > 0) {
        const file = input.files[0];
        const { type, size } = file;

        const ALLOWED_MIME_TYPES = ["image/jpeg", "image/png", "image/jpg"];
        const MAX_SIZE_MB = 5;

        if (!ALLOWED_MIME_TYPES.includes(type)) {
          errorMessage.value = "Only JPG, JPEG, and PNG files are allowed.";
          return;
        }

        if (size > MAX_SIZE_MB * 1024 * 1024) {
          errorMessage.value = "File size exceeds 5MB.";
          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          imagePreview.value = reader.result as string;
        };
        reader.readAsDataURL(file);
      }
    };

    const handleChangeClick = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    const handleCloseClick = () => {
      imagePreview.value = "";
    };

    const populateFields = () => {
      linkedinUrl.value = authStore.profileInformation.linkedinUrl;
      selectedIndustry.value = authStore.profileInformation.industry;
      imagePreview.value = authStore.profileInformation.profilePicture;
      title.value = authStore.profileInformation.title;
      company.value = authStore.profileInformation.companyName;
      selectedLanguages.value =
        authStore.profileInformation.spokenLanguages.length > 0
          ? authStore.profileInformation.spokenLanguages
          : ["English"];
      populateCountryAndRegion(
        authStore.profileInformation.country,
        authStore.profileInformation.state,
        "name"
      );
    };

    const populateCountryAndRegion = async (
      countryToSet: string,
      regionToSet: string,
      searchKey: "name" | "code"
    ) => {
      const matchedCountry = countries.value
        .map(([name, code, regions]) => ({
          name,
          code,
          regions: regions.map(([regionName, shortCode]) => ({
            name: regionName,
            shortCode,
          })),
        }))
        .find((country) => country[searchKey] === countryToSet);

      if (matchedCountry) {
        selectedCountry.value = {
          countryName: matchedCountry.name,
          countrySlug: matchedCountry.code,
          regions: matchedCountry.regions,
        };
      }

      await nextTick();
      const closestRegion = findClosestRegion(regionToSet, regions.value);

      if (closestRegion) {
        selectedRegion.value = {
          name: closestRegion.name,
          shortCode: closestRegion.shortCode,
        };
      }
    };

    const addLanguage = () => {
      if (!selectedLanguages.value.includes(languageToAdd.value)) {
        selectedLanguages.value = [
          ...selectedLanguages.value,
          languageToAdd.value,
        ];
      }
      languageToAdd.value = "";
    };

    const removeLanguage = (language: string) => {
      selectedLanguages.value = selectedLanguages.value.filter(
        (l) => l !== language
      );
    };

    const profilePictureUrl = computed(() => {
      const baseUrl = imagePreview.value;
      if (
        imagePreview.value.includes(
          `https://${process.env.VUE_APP_S3_BUCKET}.s3.amazonaws.com/uploads/user`
        )
      ) {
        let timestamp = new Date().getTime();
        return `${baseUrl}?t=${timestamp}`;
      } else if (imagePreview.value.startsWith("https://")) {
        return baseUrl;
      } else {
        return imagePreview.value;
      }
    });

    return {
      industries,
      selectedIndustry,
      selectedCountry,
      selectedRegion,
      company,
      isFetching,
      handleSubmit,
      fetchLinkedinData,
      linkedinUrl,
      errorMessage,
      handleUploadClick,
      handleFileUpload,
      imagePreview,
      fileInput,
      handleChangeClick,
      handleCloseClick,
      updateUserProfile,
      submitDisabled,
      authStore,
      title,
      profilePictureUrl,
      selectedLanguages,
      removeLanguage,
      spokenLanguages,
      addLanguage,
      languageToAdd,
      countries,
      regions,
      isCountryDropdownOpen,
      isRegionDropdownOpen,
      countrySearchQuery,
      regionSearchQuery,
      toggleDropdown,
      filteredCountries,
      filteredRegions,
      selectCountry,
      selectRegion,
      industrySearchQuery,
      isIndustryDropdownOpen,
      filteredIndustries,
      selectIndustry,
    };
  },
});
</script>

<style>
/* Spinner rotation animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Spinner color animation */
@keyframes color-change {
  0% {
    border-top-color: #8b5cf6; /* Violet color */
  }
  50% {
    border-top-color: #fff; /* White color */
  }
  100% {
    border-top-color: #8b5cf6; /* Violet color */
  }
}

/* Apply animations */
.spinner {
  animation: spin 1s linear infinite, color-change 1s linear infinite;
}
</style>
