<template>
  <div class="flex flex-col w-4/5 max:md-w-1/2 space-y-4">
    <!-- Name Input Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-6 border-b border-gray-200 max-w-[1000px]"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5">Name</span>
      </div>
      <div class="flex flex-col flex-1 gap-2 text-left">
        <div class="flex gap-2 w-full">
          <div class="flex-1">
            <BaseInput
              id="firstName"
              v-model="localFirstName"
              type="text"
              className="px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap"
              required
              @change="$emit('update:firstName', localFirstName)"
            />
          </div>
          <div class="flex-1">
            <BaseInput
              id="lastName"
              v-model="localLastName"
              type="text"
              className="px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap"
              required
              @change="$emit('update:lastName', localLastName)"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Email Input Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200 max-w-[1000px]"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5"
          >Email Address</span
        >
      </div>
      <div class="flex flex-col flex-1 text-left">
        <BaseInput
          id="email"
          v-model="localEmailAddress"
          type="text"
          className="px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap"
          required
          @change="$emit('update:email', localEmailAddress)"
        />
      </div>
    </div>

    <!-- Profile Photo Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200 max-w-[1000px]"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5"
          >Your photo</span
        >
        <span class="text-gray-500 text-sm font-normal leading-5"
          >Your photo will be displayed on your profile.</span
        >
      </div>
      <div class="flex flex-wrap flex-1 text-left gap-4 items-center">
        <div
          v-if="imageSrc"
          class="relative w-9 h-9 overflow-hidden rounded-full flex items-center justify-center"
        >
          <img
            loading="lazy"
            :src="profilePictureUrl"
            alt="User profile picture"
            class="w-full h-full object-cover"
          />
          <div class="absolute inset-0"></div>
        </div>
        <div v-else class="flex flex-col">
          <div
            class="flex items-center justify-center w-8 h-8 bg-primary-purple text-white rounded-full"
          >
            {{ initials() }}
          </div>
        </div>
        <div class="flex flex-col">
          <BaseButton
            type="button"
            @click="handleChangeClick"
            class="px-4 py-2 text-xs sm:text-sm font-medium leading-none text-black border border-gray-300 rounded-md bg-transparent"
          >
            Change
          </BaseButton>
        </div>
        <input
          ref="fileInput"
          type="file"
          id="imageUpload"
          class="sr-only"
          accept="image/png,image/jpeg,image/jpg"
          @change="handleImageUpload"
        />
      </div>
    </div>

    <!-- Linkedin Profile Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200 max-w-[1000px]"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5"
          >Linkedin Profile</span
        >
      </div>
      <div class="flex flex-col flex-1 text-left">
        <BaseInput
          id="linkedinProfile"
          v-model="localLinkedinProfile"
          type="text"
          className="px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap"
          required
          @change="$emit('update:linkedinProfile', localLinkedinProfile)"
        />
      </div>
    </div>

    <!-- Title Input Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200 max-w-[1000px]"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5">Title</span>
      </div>
      <div class="flex flex-col flex-1 text-left">
        <BaseInput
          id="title"
          v-model="localTitle"
          type="text"
          className="px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap"
          required
          @change="$emit('update:title', localTitle)"
        />
      </div>
    </div>

    <!-- Company Name Input Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200 max-w-[1000px]"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5">Company</span>
      </div>
      <div class="flex flex-col flex-1 text-left">
        <BaseInput
          id="companyName"
          v-model="localCompanyName"
          type="text"
          className="px-3.5 py-2.5 w-full border border-gray-300 rounded-md text-sm font-normal leading-5 whitespace-nowrap"
          required
          @change="$emit('update:companyName', localCompanyName)"
        />
      </div>
    </div>

    <!-- Country Input Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200 max-w-[1000px]"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5">Country</span>
      </div>
      <div class="flex flex-col flex-1 text-left">
        <BaseSearchableDropdown
          v-model="localCountry"
          :options="countries.map((country) => country[0])"
          :placeholder="'Select country'"
          class="w-full"
          @change="handleCountrySelection"
        />
      </div>
    </div>

    <!-- State Input Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200 max-w-[1000px]"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5">State</span>
      </div>
      <div class="flex flex-col flex-1 text-left">
        <BaseSearchableDropdown
          v-model="localState"
          :options="states.map((state) => state.name)"
          :placeholder="'Select state'"
          class="w-full"
          @change="handleStateSelection"
        />
      </div>
    </div>

    <!-- State Input Block -->
    <div
      class="flex flex-wrap justify-between gap-6 py-4 border-b border-gray-200 max-w-[1000px]"
    >
      <div class="flex flex-col w-1/3 text-left">
        <span class="text-gray-700 text-sm font-medium leading-5"
          >Languages</span
        >
      </div>
      <div class="flex flex-col flex-1 text-left">
        <BaseSearchableDropdown
          v-model="selectedLanguage"
          :options="spokenLanguages.map((language) => language)"
          :placeholder="'Select language'"
          class="w-full"
          @change="addLanguage"
        />
        <div class="flex flex-wrap gap-2 mt-2">
          <div
            v-for="(language, index) in localLanguages"
            :key="index"
            class="px-2 py-1 text-xs sm:text-sm font-medium leading-none text-black border border-gray-300 rounded-md bg-transparent"
          >
            <div class="flex items-center gap-2">
              {{ language }}
              <BaseButton
                type="button"
                @click="removeLanguage(index)"
                class="px-2 text-xs sm:text-sm font-medium leading-none bg-gray-300 text-black border border-gray-300 rounded-full bg-transparent"
              >
                x
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import BaseInput from "@/components/shared/BaseInput.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import BaseSearchableDropdown from "@/components/shared/BaseSearchableDropdown.vue";
import { allCountries } from "country-region-data";
import { State } from "@/types/user/types";
import { spokenLanguages } from "@/data/spoken-languages";

export default defineComponent({
  name: "CurrentDetails",
  components: {
    BaseInput,
    BaseButton,
    BaseSearchableDropdown,
  },
  props: {
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    profilePicture: {
      type: String,
      required: true,
    },
    linkedinProfile: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    companyName: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    state: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const localProfilePicture = ref(props.profilePicture);
    const localFirstName = ref(props.firstName);
    const localLastName = ref(props.lastName);
    const localEmailAddress = ref(props.email);
    const localLinkedinProfile = ref(props.linkedinProfile);
    const localTitle = ref(props.title);
    const localCompanyName = ref(props.companyName);
    const localCountry = ref(props.country);
    const localState = ref(props.state);
    const localLanguages = ref(props.languages);
    const errorMessage = ref("");
    const imageSrc = ref(props.profilePicture);
    const countries = ref(allCountries);
    const selectedLanguage = ref("");
    const languageSearchQuery = ref("");
    const states = ref<State[]>([]);
    const fileInput = ref<HTMLInputElement | null>(null);

    const handleChangeClick = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    const handleImageUpload = (event: Event) => {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        const file = input.files[0];
        const { type, size } = file;

        const ALLOWED_MIME_TYPES = ["image/jpeg", "image/png", "image/jpg"];
        const MAX_SIZE_MB = 5;

        errorMessage.value = "";

        if (!ALLOWED_MIME_TYPES.includes(type)) {
          errorMessage.value = "Only JPG, JPEG, and PNG files are allowed.";
          return;
        }

        if (size > MAX_SIZE_MB * 1024 * 1024) {
          errorMessage.value = "File size exceeds 5MB.";
          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          imageSrc.value = reader.result as string;
          emit("update:profilePicture", getCleanBase64(imageSrc.value));
        };
        reader.readAsDataURL(file);

        input.value = "";
      }
    };

    const getCleanBase64 = (base64String: string) => {
      return base64String.replace(/^data:image\/(jpeg|jpg|png);base64,/, "");
    };

    const profilePictureUrl = computed(() => {
      if (imageSrc.value.startsWith("https://")) {
        return `${imageSrc.value}?t=${new Date().getTime()}`;
      } else {
        return imageSrc.value;
      }
    });

    const initials = () => {
      if (localFirstName.value && localLastName.value) {
        const firstInitial = localFirstName.value.charAt(0).toUpperCase();
        const lastInitial = localLastName.value.charAt(0).toUpperCase();
        return `${firstInitial}${lastInitial}`;
      }
      return "";
    };

    const handleCountrySelection = (selectedCountry: string) => {
      const selectedCountryData = countries.value.find(
        (country) => country[0] === selectedCountry
      );
      localCountry.value = selectedCountry;
      localState.value = "";
      states.value = (selectedCountryData?.[2] || []).map(
        ([name, shortCode]) => ({
          name,
          shortCode,
        })
      );
      emit("update:country", selectedCountry);
      emit("update:state", localState.value);
    };

    const handleStateSelection = (selectedState: string) => {
      emit("update:state", selectedState);
    };

    const addLanguage = () => {
      if (!localLanguages.value.includes(selectedLanguage.value)) {
        localLanguages.value = [
          ...localLanguages.value,
          selectedLanguage.value,
        ];
      }
      selectedLanguage.value = "";
      emit("update:languages", localLanguages.value);
    };

    const removeLanguage = (index: number) => {
      localLanguages.value = localLanguages.value.filter((_, i) => i !== index);
      emit("update:languages", localLanguages.value);
    };

    const initializeStates = () => {
      const selectedCountryData = countries.value.find(
        (country) => country[0] === localCountry.value
      );
      states.value = (selectedCountryData?.[2] || []).map(
        ([name, shortCode]) => ({
          name,
          shortCode,
        })
      );
    };

    const filteredLanguages = computed(() =>
      spokenLanguages.filter((language) =>
        language.toLowerCase().includes(languageSearchQuery.value.toLowerCase())
      )
    );

    onMounted(() => {
      if (localCountry.value) {
        initializeStates();
      }
    });

    return {
      localProfilePicture,
      localFirstName,
      localLastName,
      localEmailAddress,
      localLinkedinProfile,
      localTitle,
      localCompanyName,
      localCountry,
      localState,
      countries,
      handleChangeClick,
      handleImageUpload,
      errorMessage,
      removeLanguage,
      imageSrc,
      fileInput,
      profilePictureUrl,
      initials,
      states,
      handleCountrySelection,
      handleStateSelection,
      addLanguage,
      localLanguages,
      spokenLanguages,
      filteredLanguages,
      languageSearchQuery,
      selectedLanguage,
    };
  },
});
</script>
