<template>
  <div class="flex min-h-screen flex-col bg-white">
    <div
      v-if="isFetchingUserProfile"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <!-- Spinner -->
      <div
        class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
      ></div>
    </div>
    <header class="flex flex-col w-full border-b max-md:max-w-full">
      <ExpertiseNavbar :activeTab="1" />
    </header>
    <div class="flex justify-center mb-4">
      <div
        v-if="errorMessage"
        role="alert"
        class="flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
        style="max-height: 80px; overflow-y: auto"
      >
        <!-- Icon Container -->
        <div
          class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
        >
          <!-- Custom Image Icon -->
          <img
            src="../assets/shared/failure.svg"
            alt="Error Icon"
            class="w-4 h-4"
          />
        </div>
        <p>{{ errorMessage }}</p>
      </div>
    </div>
    <main
      class="flex flex-col items-start pl-10 mt-10 w-full max-md:pl-5 max-md:max-w-full"
    >
      <h1 class="text-3xl font-semibold leading-tight text-black">
        My Expert Programs
      </h1>
      <section
        class="flex z-10 flex-row items-center justify-start gap-2.5 mt-1 w-full md:w-1/2"
      >
        <div
          class="flex flex-col cursor-pointer border border-2 mb-1 rounded-full p-0.5"
          :class="{
            'border-primary-purple': isScrolledLeft,
            'border-gray-300': !isScrolledLeft,
          }"
          v-if="navItems.length > 0"
          @click="scrollTo('left')"
        >
          <div
            v-html="ARROW_LEFT_ICON"
            :class="{
              'text-primary-purple': isScrolledLeft,
              'text-gray-300': !isScrolledLeft,
            }"
          ></div>
        </div>
        <nav
          id="programsNavbar"
          class="flex gap-8 justify-start px-4 text-sm font-medium leading-none text-gray-500 overflow-x-auto whitespace-nowrap scrollbar-hidden hover:scrollbar-visible"
        >
          <!-- Navigation Items -->
          <div class="flex gap-2 px-1">
            <button
              v-for="item in navItems"
              :key="item.id"
              :id="`navButton-${item.id}`"
              :class="[
                'flex flex-col items-center border border-solid rounded-md px-2 py-1',
                getNavItemClass(item),
              ]"
              @click="handleNavItemClick(item)"
            >
              <div class="flex flex-row items-center pr-6">
                <!-- Program Logo and Name -->
                <div class="flex items-center">
                  <img
                    loading="lazy"
                    :src="programLogo(item)"
                    alt="program logo"
                    class="w-5 h-5 object-cover rounded-full"
                  />
                  <span class="px-1">{{ item.name }}</span>
                  <!-- Status Indicators -->
                  <span v-if="navItemTitle(item) === 'pending'" class="ml-1"
                    >(pending)</span
                  >
                  <span v-if="navItemTitle(item) === 'approved'" class="ml-1">
                    <div v-html="APPROVED_ICON" class="text-green-600"></div>
                  </span>
                  <span v-if="navItemTitle(item) === 'rejected'" class="ml-1">
                    <div v-html="REJECTED_ICON" class="text-red-600"></div>
                  </span>
                </div>
              </div>

              <!-- Active State Indicator -->
              <div
                class="w-full"
                :class="{ 'bg-primary-purple h-1': isActive(item) }"
              ></div>
            </button>
          </div>
        </nav>
        <div
          class="flex flex-col cursor-pointer border border-2 mb-1 rounded-full p-0.5"
          :class="{
            'border-primary-purple': isScrolledRight,
            'border-gray-300': !isScrolledRight,
          }"
          v-if="navItems.length > 0"
          @click="scrollTo('right')"
        >
          <div
            v-html="ARROW_RIGHT_ICON"
            :class="{
              'text-primary-purple': isScrolledRight,
              'text-gray-300': !isScrolledRight,
            }"
          ></div>
        </div>
        <div class="flex flex-col items-center justify-center mt-4">
          <div class="flex flex-col items-center justify-center mb-4">
            <div
              class="relative flex flex-col items-center justify-center mb-1"
            >
              <!-- Icon container -->
              <span
                @mouseover="showTooltip = true"
                @mouseleave="showTooltip = false"
                class="flex flex-col items-center"
              >
                <div
                  @click="handleAddProgram"
                  class="flex z-20 ml-3 overflow-hidden justify-center items-center px-1 w-8 h-8 bg-sky-50 rounded-2xl"
                >
                  <div v-html="CIRCLE_PLUS" class="text-primary-purple"></div>
                </div>
                <!-- Floating text -->
                <span
                  v-if="showTooltip"
                  class="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-3 bg-black text-white text-xs rounded py-1 px-2 whitespace-nowrap"
                >
                  Join Another Expert Program
                  <!-- Triangle below the tooltip -->
                  <span
                    class="absolute top-full left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-4 border-r-4 border-t-4 border-transparent border-t-black"
                  ></span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </section>
    </main>
    <div class="flex flex-col" v-if="selectedProgram">
      <div
        v-if="selectedProgram?.companyId"
        class="z-10 w-full max-md:max-w-full flex-grow flex flex-col"
      >
        <div class="flex border-t max-md:flex-col flex-grow">
          <section
            class="flex flex-col w-[76%] max-md:ml-0 max-md:w-full max-md:order-2"
          >
            <div class="flex flex-wrap grow max-md:mt-6">
              <article
                class="flex flex-col grow shrink-0 mt-5 basis-0 w-fit max-md:mt-10 max-md:max-w-full"
              >
                <section
                  v-if="selectedProgramJoinRequest?.status === 'approved'"
                  class="px-7 py-2 w-full"
                >
                  <div
                    v-if="selectedProgramJoinRequest?.expertNotified === false"
                    class="flex justify-between items-center p-4 mb-4 rounded bg-green-50 w-full"
                  >
                    <div class="flex items-center">
                      <div v-html="CHECK_MARK" class="text-green-900"></div>
                      <span class="text-green-900 text-left ml-2">
                        Profile Approved!
                      </span>
                    </div>
                    <a
                      href="#"
                      @click.prevent="expertNotified"
                      class="text-green-900 hover:underline cursor-pointer"
                    >
                      Dismiss
                    </a>
                  </div>
                  <AcceptedProgramDetailsSection
                    :key="selectedProgram?.id"
                    :program="selectedProgram"
                    :profile="selectedProfile"
                    :handleChildComponentError="handleChildComponentError"
                  />
                </section>
                <section
                  v-else
                  class="flex flex-col items-start px-9 w-full max-md:px-5 max-md:max-w-full"
                >
                  <div class="flex flex-col self-stretch max-md:max-w-full">
                    <div class="flex flex-col w-full max-md:max-w-full">
                      <div
                        class="flex overflow-hidden flex-col flex-1 pt-2.5 pr-0.5 pb-0.5 pl-3.5 mt-1 w-full text-base text-gray-500 bg-white rounded-md max-md:max-w-full"
                      >
                        <div
                          v-if="
                            selectedProgramJoinRequest?.status === 'pending' &&
                            !editInformation
                          "
                          class="flex justify-between items-center p-4 rounded bg-yellow-50"
                        >
                          <span class="text-yellow-800 text-left">
                            This is under review. Team
                            {{ selectedProgram?.name }} will review your
                            application and you will be notified of their
                            response via email..
                          </span>
                          <a
                            href="#"
                            @click.prevent="enableEditing"
                            class="text-primary-purple hover:underline cursor-pointer"
                          >
                            Edit Information
                          </a>
                        </div>
                        <div
                          class="flex flex-wrap gap-5 justify-between items-end mt-4 max-w-full"
                        >
                          <div
                            v-if="
                              !selectedProfile?.introductionVideo ||
                              (selectedProfile.introductionVideo &&
                                editInformation)
                            "
                            class="flex flex-col items-center space-y-4"
                          >
                            <div class="flex flex-col space-y-4">
                              <!-- Text div -->
                              <div class="flex flex-col items-start">
                                <!-- Text div for "Record a video" -->
                                <div
                                  class="text-gray-900 text-left font-medium text-base leading-6"
                                >
                                  <span v-if="!selectedProfile"
                                    >Please record a short video ( &lt; 90
                                    seconds ) introducing yourself to the
                                    program manager.
                                  </span>
                                  <span
                                    v-if="selectedProfile && editInformation"
                                    >Record a new short video ( &lt; 90 seconds
                                    ), or submit existing one</span
                                  >
                                </div>

                                <div
                                  class="text-gray-500 text-left text-sm font-normal leading-1"
                                >
                                  This is private and confidential and only
                                  viewed once to approve you into the program.
                                </div>

                                <div
                                  class="mt-4 text-gray-900 text-left font-medium text-base leading-6"
                                >
                                  <p>Tell us about:</p>
                                  <ul
                                    class="text-gray-800 text-left text-sm font-normal leading-1"
                                  >
                                    <li>
                                      Your experience with
                                      {{ selectedProgram?.name }}
                                    </li>
                                    <li>
                                      Your role and how you use the product
                                    </li>
                                    <li>Why you'd like to join as an Expert</li>
                                  </ul>
                                </div>
                              </div>
                              <div
                                class="flex flex-col items-center w-full max-w-lg p-4"
                              >
                                <!-- Video element with conditional visibility -->
                                <video
                                  ref="video"
                                  class="w-full h-auto max-w-1/2 border border-gray-300"
                                  controls
                                  v-show="isRecording || selectedFile"
                                ></video>

                                <!-- Placeholder for no recording -->
                                <div
                                  class="w-full h-auto max-w-full border border-gray-300 flex flex-col items-center justify-center text-gray-400"
                                  v-show="!isRecording && !selectedFile"
                                >
                                  <div v-html="VIDEO_CAMERA_ICON"></div>
                                  <p class="text-gray-500 mt-2">
                                    Recording Preview
                                  </p>
                                </div>

                                <!-- Recording control buttons -->
                                <div class="flex flex-row gap-3 mt-4">
                                  <button
                                    @click="startRecording"
                                    :disabled="isRecording"
                                    class="px-4 py-2 bg-primary-purple text-white rounded disabled:opacity-50"
                                  >
                                    Start Recording
                                  </button>
                                  <button
                                    @click="stopRecording"
                                    :disabled="!isRecording"
                                    class="px-4 py-2 bg-gray-500 text-white rounded disabled:opacity-50"
                                  >
                                    Stop Recording
                                  </button>
                                </div>

                                <!-- Recording duration display -->
                                <div
                                  v-if="isRecording"
                                  class="text-white bg-black bg-opacity-50 px-4 py-2 rounded mt-4"
                                >
                                  <span>{{ formattedDuration }} / 01:30</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <div class="flex flex-col space-y-4 ml-3">
                              <!-- Text div -->
                              <div class="flex flex-col items-start">
                                <!-- Text div for "Record a video" -->
                                <div
                                  class="text-gray-900 font-medium text-base leading-6"
                                >
                                  Uploaded Video
                                </div>
                              </div>
                              <div
                                class="flex flex-col items-start w-2/3 max-w-lg p-4"
                              >
                                <div class="relative">
                                  <video
                                    ref="video"
                                    class="w-full h-auto max-w-1/3 border border-gray-300"
                                    controls
                                    type="video/mp4"
                                    @canplay="handleCanPlay"
                                    @loadstart="handleLoadStart"
                                    :key="selectedProfile?.introductionVideo"
                                    :src="
                                      selectedProfile?.introductionVideo || ''
                                    "
                                  ></video>
                                  <!-- Loading text, displayed when `isLoading` is true -->
                                  <div
                                    v-if="isVideoLoading"
                                    class="absolute inset-0 flex items-center justify-center bg-gray-800 text-white text-lg font-semibold z-10"
                                  >
                                    Loading video...
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-col gap-4 text-left">
                          <div class="flex flex-col gap-2">
                            <div
                              class="text-gray-900 font-medium text-base leading-6"
                            >
                              Expert Quiz
                            </div>
                            <div
                              class="text-gray-800 text-sm font-normal leading-1"
                            >
                              Question 1: Rate your expertise from 1 to 5?
                            </div>
                            <div
                              v-if="!disableEditProfile"
                              class="flex flex-col items-start space-y-4 py-6 px-4"
                            >
                              <div class="relative w-1/2 md:w-1/3">
                                <div
                                  class="absolute -translate-x-1/2 -translate-y-6 bg-primary-purple text-white text-xs font-medium px-2 py-1 rounded shadow-md"
                                  :style="{
                                    left: `${Math.max(
                                      ((expertiseSliderValue - 1) / (5 - 1)) *
                                        100,
                                      0
                                    )}%`,
                                  }"
                                >
                                  {{ expertiseSliderValue || 1 }}
                                </div>

                                <input
                                  id="slider"
                                  type="range"
                                  v-model.number="expertiseSliderValue"
                                  min="1"
                                  max="5"
                                  step="1"
                                  class="w-full h-2 bg-gray-300 rounded-lg appearance-none cursor-pointer accent-primary-purple focus:outline-none focus:ring-2 focus:ring-primary-purple focus:ring-offset-2"
                                />
                                <div class="flex flex-col">
                                  {{
                                    expertiseValue(expertiseSliderValue || 1)
                                  }}
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <div
                                class="text-gray-800 text-sm font-normal leading-1"
                              >
                                {{ expertiseSliderValue || 1 }}
                                ({{
                                  expertiseValue(expertiseSliderValue || 1)
                                }})
                              </div>
                            </div>
                            <div
                              class="text-gray-800 text-sm font-normal leading-1"
                            >
                              Question 2: How long have you been using this
                              software?
                            </div>
                            <div
                              v-if="!disableEditProfile"
                              class="flex flex-col items-start space-y-4 py-6 px-4"
                            >
                              <div class="relative w-1/2 md:w-1/3">
                                <div
                                  class="absolute -translate-x-1/2 -translate-y-6 bg-primary-purple text-white text-xs font-medium px-2 py-1 rounded shadow-md whitespace-nowrap"
                                  :style="{
                                    left: `${Math.max(
                                      ((experienceSliderValue - 1) / (5 - 1)) *
                                        100,
                                      0
                                    )}%`,
                                  }"
                                >
                                  {{
                                    experienceTimeDuration(
                                      experienceSliderValue || 1
                                    )
                                  }}
                                </div>

                                <input
                                  id="slider"
                                  type="range"
                                  v-model.number="experienceSliderValue"
                                  min="1"
                                  max="5"
                                  step="1"
                                  class="w-full h-2 bg-gray-300 rounded-lg appearance-none cursor-pointer accent-primary-purple focus:outline-none focus:ring-2 focus:ring-primary-purple focus:ring-offset-2"
                                />
                                <div class="flex flex-col">
                                  {{
                                    experienceUserType(
                                      experienceSliderValue || 1
                                    )
                                  }}
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <div
                                class="text-gray-800 text-sm font-normal leading-1"
                              >
                                {{
                                  experienceTimeDuration(
                                    experienceSliderValue || 1
                                  )
                                }}
                                ({{
                                  experienceUserType(
                                    experienceSliderValue || 1
                                  )
                                }})
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div
                  v-if="
                    selectedProgramJoinRequest?.status !== 'approved' &&
                    selectedProgramJoinRequest?.status !== 'rejected'
                  "
                  class="border-t border-solid p-3 max-md:max-w-full bg-white shadow-md z-50 mt-auto"
                >
                  <div class="flex flex-wrap gap-10">
                    <div class="flex flex-col w-1/2 ml-3">
                      <p
                        class="text-left text-sm leading-6 text-gray-500 break-words max-w-xs"
                      >
                        Team {{ selectedProgram?.name }} will review your
                        application and you will be notified of their response
                        via email.
                      </p>
                    </div>
                    <div
                      class="flex grow shrink pr-5 gap-6 self-start text-base max-md:max-w-full ml-auto"
                    >
                      <button
                        v-if="
                          !selectedProfile ||
                          (selectedProfile && editInformation)
                        "
                        type="submit"
                        :disabled="isSubmitting || isRecording"
                        @click="handleSubmit"
                        class="overflow-hidden flex-auto gap-3 self-stretch py-3.5 pr-11 pl-11 font-medium text-white bg-violet-600 rounded-md shadow-sm max-md:px-5"
                      >
                        <span v-if="isSubmitting">Submitting........</span>
                        <span v-else>Submit for Review</span>
                      </button>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </section>
          <aside
            class="flex flex-col w-[24%] border-l pl-5 max-md:ml-0 max-md:w-full max-md:order-1"
          >
            <AboutProgram :selectedProgram="selectedProgram" />
          </aside>
        </div>
      </div>
      <div v-else>
        <div
          class="relative w-full h-screen border border-gray-300 overflow-hidden"
        >
          <img
            src="../assets/expert-dashboard/no-expert-program.png"
            alt="Background Image"
            class="absolute inset-0 object-cover w-full h-full"
          />
          <div class="absolute inset-0 flex z-100 items-center justify-center">
            <div class="text-center">
              <span class="text-black text-xl font-bold"
                >No existing program</span
              >
              <p class="text-black text-lg max-w-md text-center">
                {{ selectedProgram?.name }} does not have any active programs on
                Slashexperts. We will reach out to them and update you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <JoinProgramModal
    :showAddProgramModal="showAddProgramModal"
    @update:showAddProgramModal="showAddProgramModal = $event"
  />
  <SubmitExpertiseLoader
    :isVisible="showSubmissionLoader"
    :progress="uploadProgress"
    :message="uploadMessage"
  />
  <JoinRequestSubmittedModal
    :isVisible="showJoinRequestSubmittedModal"
    :programName="requestSubmittedForProgram"
    @update:isVisible="showJoinRequestSubmittedModal = $event"
  />
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { useExpertProgramsStore } from "@/stores/expertPrograms";
import { JoinProgramRequest, Program } from "@/types/dashboard/types";
import SubmitExpertiseLoader from "../components/dashboard/SubmitExpertiseLoader.vue";
import AcceptedProgramDetailsSection from "@/components/expertise/AcceptedProgramDetailsSection.vue";
import RecordRTC from "recordrtc";
import ExpertiseNavbar from "@/components/expertise/ExpertiseNavbar.vue";
import AboutProgram from "@/components/expertise/AboutProgram.vue";
import JoinProgramModal from "@/components/expertise/JoinProgramModal.vue";
import JoinRequestSubmittedModal from "@/components/expertise/JoinRequestSubmittedModal.vue";
import { useAuthStore } from "@/stores/auth";
import {
  DASHBOARD_ICON,
  EXPERTISE_ICON,
  EARNINGS_ICON,
  APPROVED_ICON,
  REJECTED_ICON,
  CHECK_MARK,
  VIDEO_CAMERA_ICON,
  CIRCLE_EXCLAMATION,
  EARNINGS_ICON_CALIBRI,
} from "@/assets/svg/expert-overview/svgConstants";
import {
  COMPANY_LOGO,
  CLOSE_ICON,
  CIRCLE_PLUS,
  ARROW_LEFT_ICON,
  ARROW_RIGHT_ICON,
} from "@/assets/svg/shared/svgConstants";
import { Profile, UploadResult } from "@/types/expertise/types";

const CREATE_USER_PROGRAM_PROFILE = gql`
  mutation createUserProgramProfile($input: CreateUserProgramProfileInput!) {
    createUserProgramProfile(input: $input) {
      response {
        profile {
          id
          userId
          programId
          companyId
          introductionVideo
          expertise
          experience
        }
        request {
          id
          userId
          programId
          companyId
          status
          expertNotified
        }
        errors
      }
    }
  }
`;

const FETCH_USER_PROGRAM_PROFILE = gql`
  query ($params: ProfileFetchInput!) {
    fetchUserProgramProfile(params: $params) {
      profiles {
        id
        programId
        companyId
        introductionVideo
        expertise
        experience
      }
      requests {
        id
        userId
        programId
        companyId
        status
        expertNotified
      }
      errors
    }
  }
`;

const GET_PROGRAMS = gql`
  query GetPrograms($userBased: Boolean!, $joinProgramRequests: Boolean!) {
    getProgram(
      userBased: $userBased
      joinProgramRequests: $joinProgramRequests
    ) {
      programs {
        id
        name
        website
        logo
        experts
        description
        payout
        companyId
      }
    }
  }
`;

const FETCH_VIDEO_UPLOAD_LINK = gql`
  query fetchProfileVideoUploadLink(
    $programId: ID!
    $introductionVideo: Upload
    $requestTimestamp: String
  ) {
    fetchProfileVideoUploadLink(
      programId: $programId
      introductionVideo: $introductionVideo
      requestTimestamp: $requestTimestamp
    ) {
      s3PresignedPost {
        url
        fields
      }
      s3UploadPath
      errors
    }
  }
`;

const UPDATE_USER_PROGRAM_PROFILE = gql`
  mutation updateUserProgramProfile($input: UpdateUserProgramProfileInput!) {
    updateUserProgramProfile(input: $input) {
      profile {
        id
        programId
        companyId
        introductionVideo
        expertise
        experience
      }
      errors
    }
  }
`;

const UPDATE_JOIN_PROGRAM_REQUEST = gql`
  mutation updateJoinProgramRequest($input: UpdateJoinProgramRequestInput!) {
    updateJoinProgramRequest(input: $input) {
      request {
        id
        userId
        programId
        companyId
        status
        expertNotified
      }
      errors
    }
  }
`;

export default defineComponent({
  name: "ExpertExpertise",
  components: {
    SubmitExpertiseLoader,
    AcceptedProgramDetailsSection,
    ExpertiseNavbar,
    AboutProgram,
    JoinProgramModal,
    JoinRequestSubmittedModal,
  },
  setup() {
    const authStore = useAuthStore();
    const demo = ref(false);
    const showTooltip = ref(false);
    const isFetchingUserProfile = ref(false);
    const fileInput = ref<HTMLInputElement | null>(null);
    const videoUrl = ref<string | null>(null);
    const selectedVideoUrl = ref<string | null>(null);
    const selectedFile = ref<File | null>(null);
    const thumbnailUrl = ref<string | null>(null);
    const videoDuration = ref<number | null>(null);
    const uploadDate = ref<string | null>(null);
    const errorMessage = ref("");
    const navItems = ref<Program[]>([]);
    const isTextareaFocused = ref(false);
    const expertiseSliderValue = ref<number>(1);
    const experienceSliderValue = ref<number>(1);
    const selectedProfile = ref<Profile | null>(null);
    const selectedProgram = ref<Program | null>(null);
    const programProfiles = ref<Profile[]>([]);
    const programJoinRequests = ref<JoinProgramRequest[]>([]);
    const selectedProgramJoinRequest = ref<JoinProgramRequest | null>(null);
    const showJoinRequestSubmittedModal = ref(false);
    const requestSubmittedForProgram = ref("");
    const programsStore = useExpertProgramsStore();
    const showAddProgramModal = ref(false);
    const showSubmissionLoader = ref(false);
    const isSubmitting = ref(false);
    const uploadProgress = ref(0);
    const uploadMessage = ref("");
    const video = ref<HTMLVideoElement | null>(null);
    const isRecording = ref(false);
    const startTime = ref<number | null>(null);
    const duration = ref<number>(0);
    const editInformation = ref(false);
    const timeStamp = ref("");
    const showCreateSoftwareModal = ref(false);
    const isVideoLoading = ref(false);
    const isScrolledLeft = ref(false);
    const isScrolledRight = ref(false);
    let stream: MediaStream | null = null;
    let recorder: RecordRTC | null = null;
    let durationInterval: ReturnType<typeof setInterval> | null = null;

    const formattedDuration = computed(() => {
      const minutes = Math.floor(duration.value / 60);
      const seconds = Math.floor(duration.value % 60);
      if (duration.value > 90) {
        stopRecording();
      }
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    });

    const startRecording = async () => {
      try {
        // isSubmitting.value = true;
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 640 },
            height: { ideal: 480 },
            frameRate: { ideal: 15 },
          },
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: false,
            sampleRate: 44100,
            channelCount: 1,
          },
        });

        if (video.value) {
          video.value.srcObject = stream;
          video.value.muted = true;
          video.value.controls = false;
          video.value.play();
        }

        recorder = new RecordRTC(stream, {
          type: "video",
          mimeType: "video/mp4",
        });

        recorder.startRecording();
        startTime.value = Date.now();
        isRecording.value = true;

        durationInterval = setInterval(() => {
          if (startTime.value) {
            duration.value = Math.floor((Date.now() - startTime.value) / 1000);
          }
        }, 1000);
      } catch (error) {
        console.error("Error starting recording:", error);
      }
    };

    const stopRecording = () => {
      // isSubmitting.value = false;
      errorMessage.value = "";
      if (recorder) {
        recorder.stopRecording(() => {
          const blob = recorder.getBlob();
          if (blob) {
            selectedFile.value = new File([blob], "recording.mp4", {
              type: "video/mp4",
            });

            const recordedVideoUrl = URL.createObjectURL(blob);
            if (video.value) {
              video.value.srcObject = null;
              video.value.src = recordedVideoUrl;
              video.value.controls = true;
              video.value.muted = false;
              video.value.play();
            }
          } else {
            console.error("The recorder did not return a valid Blob.");
          }

          isRecording.value = false;
          if (durationInterval) {
            clearInterval(durationInterval);
            durationInterval = null;
          }
          duration.value = 0;
          startTime.value = null;

          if (stream) {
            stream.getTracks().forEach((track) => {
              track.stop();
            });
            stream = null;
          }
        });
      }
    };

    const setExpertiseAndExperience = (
      expertise: number,
      experience: number
    ) => {
      expertiseSliderValue.value = expertise;
      experienceSliderValue.value = experience;
    };

    onUnmounted(() => {
      if (durationInterval) {
        clearInterval(durationInterval);
      }
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    });

    onMounted(async () => {
      try {
        isFetchingUserProfile.value = true;
        const {
          result: profilesResult,
          refetch: refetchProgramProfiles,
          error: profilesError,
        } = await useQuery(FETCH_USER_PROGRAM_PROFILE, {
          params: {
            currentUser: true,
            fetchRequests: true,
          },
        });

        await refetchProgramProfiles();

        watch(
          [() => profilesResult.value, () => programsStore.programs],
          ([queryResult, programs]) => {
            if (queryResult) {
              programProfiles.value =
                queryResult.fetchUserProgramProfile.profiles;
              programsStore.setProgramProfiles =
                queryResult.fetchUserProgramProfile.profiles;
              programJoinRequests.value =
                queryResult.fetchUserProgramProfile.requests;
              navItems.value = programs;
              programs.forEach((program: Program) => {
                updateFocusedProgram(program);
              });
            }
            setInvitedProgram();
            updateScrolls();
            isFetchingUserProfile.value = false;
          },
          { immediate: true }
        );

        watch(
          () => profilesError.value,
          (error) => {
            if (error) {
              errorMessage.value =
                "Failed to fetch necessary data, please refresh the page to try again.";
              scrollToTop();
              isFetchingUserProfile.value = false;
            }
          },
          { immediate: true }
        );

        const {
          result: programsResult,
          refetch: refetchPrograms,
          error: programsError,
        } = await useQuery(GET_PROGRAMS, {
          userBased: true,
          joinProgramRequests: true,
        });

        await refetchPrograms();

        watch(
          () => programsResult.value,
          (programsQuery) => {
            if (programsQuery) {
              programsStore.setPrograms(programsQuery.getProgram.programs);
              setInvitedProgram();
            }
          },
          { immediate: true }
        );

        watch(
          () => programsError.value,
          (error) => {
            if (error) {
              errorMessage.value =
                "Failed to fetch necessary data, please refresh the page to try again.";
              scrollToTop();
              isFetchingUserProfile.value = false;
            }
          },
          { immediate: true }
        );
      } catch (error) {
        errorMessage.value =
          "Failed to fetch necessary data, please refresh the page to try again.";
        scrollToTop();
        isFetchingUserProfile.value = false;
      }

      setTimeout(() => {
        scrollIntoView();
      }, 500);
      setTimeout(() => {
        checkNavbarScrollability();
      }, 1000);
    });

    const scrollIntoView = () => {
      if (selectedProgram.value) {
        const activeButton = document.querySelector(
          `#navButton-${selectedProgram.value?.id}`
        );
        if (activeButton) {
          activeButton.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
      }
    };

    const updateScrolls = async () => {
      await nextTick();
      checkNavbarScrollability();
      scrollIntoView();
    };

    const {
      mutate: createUserProgramProfile,
      onDone,
      onError,
    } = useMutation(CREATE_USER_PROGRAM_PROFILE);

    const {
      mutate: updateUserProgramProfile,
      onDone: onUpdateProfileDone,
      onError: onUpdateProfileError,
    } = useMutation(UPDATE_USER_PROGRAM_PROFILE);

    const {
      mutate: updateJoinProgramRequest,
      onDone: onUpdateJoinProgramRequestDone,
      onError: onUpdateJoinProgramRequestError,
    } = useMutation(UPDATE_JOIN_PROGRAM_REQUEST);

    const updateFocusedProgram = (program: Program) => {
      editInformation.value = false;
      const focusedProgramId =
        programsStore.selectedProgram?.id || programsStore.programs[0].id;
      if (program.id == focusedProgramId) {
        selectedProgram.value = program;
        if (programProfiles.value) {
          selectedProfile.value =
            programProfiles.value.find(
              (profile) => profile.programId === focusedProgramId
            ) || null;
        }
        if (programJoinRequests.value) {
          selectedProgramJoinRequest.value =
            programJoinRequests.value.find(
              (request) => request.programId === focusedProgramId
            ) || null;
        }
        selectedVideoUrl.value = selectedProfile.value?.introductionVideo || "";
        selectedFile.value = null;
        if (selectedProfile.value) {
          setExpertiseAndExperience(
            selectedProfile.value.expertise,
            selectedProfile.value.experience
          );
        } else {
          setExpertiseAndExperience(0, 0);
        }
      }
    };

    const getNavItemClass = (item: Program): string => {
      return item.name === selectedProgram?.value?.name
        ? "text-primary-purple border-primary-purple"
        : "border-gray-300";
    };

    const isActive = (item: Program): boolean => {
      return item.id === selectedProgram?.value?.id;
    };

    const handleNavItemClick = (program: Program) => {
      if (isRecording.value) {
        errorMessage.value =
          "Please stop recording before switching to another expert program";
        scrollToTop();
      } else {
        errorMessage.value = "";
        programsStore.setSelectedProgram(program);
        updateFocusedProgram(program);
        if (selectedProfile.value?.introductionVideo) {
          selectedVideoUrl.value = selectedProfile.value?.introductionVideo;
        } else {
          selectedVideoUrl.value = "";
        }
      }
    };

    const formatDuration = (seconds: number) => {
      const minutes = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
    };

    const handleSubmit = async () => {
      isSubmitting.value = true;
      errorMessage.value = "";

      const useExistingVideo =
        selectedProfile.value?.introductionVideo && !selectedFile.value;

      if (useExistingVideo) {
        updateProfile(selectedProfile.value?.introductionVideo || "");
      } else {
        handleCreate();
      }
    };

    const handleCreate = () => {
      if (!selectedFile.value) {
        errorMessage.value = "Please record a video";
        isSubmitting.value = false;
        scrollToTop();
        return;
      }
      uploadProgress.value = 0;
      showSubmissionLoader.value = true;
      uploadMessage.value = "Uploading your details";

      const formData = new FormData();
      formData.append("introductionVideo", selectedFile.value);
      const formDataObject: Record<string, File | string> = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });

      uploadVideo(formDataObject);
    };

    const uploadVideo = (videoFile: Record<string, File | string>) => {
      const timestampInMilliseconds = Date.now();
      timeStamp.value = Math.floor(timestampInMilliseconds / 1000).toString();

      const { result: directVideoUploadResult, error: directVideoUploadError } =
        useQuery(FETCH_VIDEO_UPLOAD_LINK, {
          programId: selectedProgram.value?.id,
          introductionVideo: videoFile.introductionVideo,
          requestTimestamp: timeStamp.value,
        });

      watch(
        () => directVideoUploadResult.value,
        async (directUpload) => {
          if (directUpload) {
            if (directUpload?.fetchProfileVideoUploadLink?.errors?.length > 0) {
              errorMessage.value =
                directUpload.fetchProfileVideoUploadLink.errors.join(". ");
              showSubmissionLoader.value = false;
              isSubmitting.value = false;
              scrollToTop();
            } else {
              const s3UploadLink =
                directUpload.fetchProfileVideoUploadLink.s3PresignedPost;
              const s3UploadPath =
                directUpload.fetchProfileVideoUploadLink.s3UploadPath;
              const videoUploadResult = await uploadToS3(
                s3UploadLink.url,
                s3UploadLink.fields,
                videoFile.introductionVideo
              );

              const result = handleVideoUploadResult(videoUploadResult);
              if (result) {
                uploadMessage.value = "Wrapping up a few things.....";
                profileSubmission(s3UploadPath);
              }
            }
          }
        }
      );

      watch(
        () => directVideoUploadError.value,
        (directUploadError) => {
          if (directUploadError) {
            showSubmissionLoader.value = false;
            errorMessage.value = "Something went wrong";
            scrollToTop();
            isSubmitting.value = false;
          }
        }
      );
    };

    const profileSubmission = (s3_url: string) => {
      if (!selectedProfile.value) {
        createProfile(s3_url);
      } else if (selectedProfile.value && editInformation) {
        updateProfile(s3_url);
      }
    };

    const createProfile = (s3_url: string) => {
      createUserProgramProfile({
        input: {
          params: {
            programId: selectedProgram?.value?.id,
            introductionVideo: s3_url,
            expertise: expertiseSliderValue.value || 1,
            experience: experienceSliderValue.value || 1,
          },
        },
      });

      onDone(async (response) => {
        try {
          isSubmitting.value = false;
          if (
            response &&
            response.data.createUserProgramProfile.response.errors.length > 0
          ) {
            errorMessage.value =
              response.data.createUserProgramProfile.response.errors.join(". ");
            scrollToTop();
          } else {
            const responseData =
              response.data.createUserProgramProfile.response;
            programsStore.addProgramProfiles(responseData.profile);
            selectedProfile.value = responseData.profile;
            programProfiles.value = [
              ...(programProfiles.value || []),
              responseData.profile,
            ];

            selectedProgramJoinRequest.value = responseData.request;
            programJoinRequests.value = [
              ...(programJoinRequests.value || []),
              responseData.request,
            ];

            showSuccessModal();
          }
          showSubmissionLoader.value = false;
          selectedFile.value = null;
        } catch (error) {
          isSubmitting.value = false;
          showSubmissionLoader.value = false;
          errorMessage.value = "Something went wrong";
          scrollToTop();
        }
      });

      onError((error: ApolloError) => {
        isSubmitting.value = false;
        showSubmissionLoader.value = false;
        errorMessage.value = "Something went wrong";
        scrollToTop();
        console.error("Profile update failed:", error.message);
        console.error("GraphQL Errors:", error.graphQLErrors);
        console.error("Network Error:", error.networkError);
      });
    };

    const updateProfile = (s3_url: string) => {
      updateUserProgramProfile({
        input: {
          params: {
            profileId: selectedProfile.value?.id,
            introductionVideo: s3_url,
            expertise: expertiseSliderValue.value || 1,
            experience: experienceSliderValue.value || 1,
          },
        },
      });

      onUpdateProfileDone((response) => {
        if (response) {
          if (response?.errors) {
            errorMessage.value = "Something went wrong";
            scrollToTop();
          } else {
            const errors = response.data.updateUserProgramProfile.errors;
            isSubmitting.value = false;
            showSubmissionLoader.value = false;
            if (errors.length > 0) {
              errorMessage.value = errors.join(". ");
              scrollToTop();
            } else {
              editInformation.value = false;
              selectedFile.value = null;
              const index = programProfiles.value.findIndex(
                (profile) =>
                  profile.id ===
                  response.data.updateUserProgramProfile.profile.id
              );

              if (index !== -1) {
                const updatedProfiles = [...programProfiles.value];
                updatedProfiles[index] = {
                  ...updatedProfiles[index],
                  ...response.data.updateUserProgramProfile.profile,
                };

                programProfiles.value = updatedProfiles;
              }
            }
            showSuccessModal();
          }
        }
      });

      onUpdateProfileError((error: ApolloError) => {
        isSubmitting.value = false;
        showSubmissionLoader.value = false;
        errorMessage.value = "Something went wrong";
        scrollToTop();
        console.error("Profile update failed:", error.message);
        console.error("GraphQL Errors:", error.graphQLErrors);
        console.error("Network Error:", error.networkError);
      });
    };

    const showSuccessModal = () => {
      showJoinRequestSubmittedModal.value = true;
      requestSubmittedForProgram.value = selectedProgram.value?.name || "";
    };

    const handleVideoUploadResult = (uploadResult: UploadResult): boolean => {
      if (uploadResult.success) {
        return true;
      } else {
        errorMessage.value = uploadResult.error || "";
        showSubmissionLoader.value = false;
        isSubmitting.value = false;
        scrollToTop();
        return false;
      }
    };

    const uploadToS3 = (
      url: string,
      fields: Record<string, string>,
      file: File | string
    ): Promise<{ success: boolean; error?: string }> => {
      return new Promise((resolve) => {
        const formData = new FormData();
        Object.entries(fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", file);

        const xhr = new XMLHttpRequest();
        xhr.open("POST", url);

        xhr.upload.addEventListener("progress", (event) => {
          if (event.lengthComputable) {
            const progress = Math.round((event.loaded / event.total) * 100);
            uploadProgress.value = progress;
          }
        });

        xhr.addEventListener("load", () => {
          if (xhr.status === 200 || xhr.status === 204) {
            resolve({ success: true });
          } else {
            const error = `Upload failed with status: ${xhr.status}. Response: ${xhr.responseText}`;
            resolve({ success: false, error });
          }
        });

        xhr.addEventListener("error", () => {
          const error = "Upload error due to a network issue or server error.";
          resolve({ success: false, error });
        });

        xhr.send(formData);
      });
    };

    const handleAddProgram = () => {
      errorMessage.value = "";
      showAddProgramModal.value = true;
    };

    const enableEditing = () => {
      editInformation.value = true;
    };

    const handleCanPlay = () => {
      isVideoLoading.value = false;
    };

    const handleLoadStart = () => {
      isVideoLoading.value = true;
    };

    const expertNotified = () => {
      errorMessage.value = "";
      updateJoinProgramRequest({
        input: {
          requestParams: {
            requestId: selectedProgramJoinRequest.value?.id,
            expertNotified: true,
          },
        },
      });

      onUpdateJoinProgramRequestDone((response) => {
        if (response) {
          if (response?.errors) {
            errorMessage.value = "Something went wrong";
            scrollToTop();
          } else {
            const responseData = response.data.updateJoinProgramRequest;
            const errors = responseData.errors;
            if (errors.length > 0) {
              errorMessage.value = errors.join(". ");
              scrollToTop();
            } else {
              const index = programJoinRequests.value.findIndex(
                (request) => request.id === responseData.request.id
              );
              if (index !== -1) {
                const updatedRequests = [...programJoinRequests.value];
                updatedRequests[index] = {
                  ...updatedRequests[index],
                  ...responseData.request,
                };

                programJoinRequests.value = updatedRequests;
              }
            }
          }
        }
      });

      onUpdateJoinProgramRequestError((mutationError: ApolloError) => {
        if (mutationError) {
          errorMessage.value = `Failed to dismiss.`;
          scrollToTop();
        }
      });
    };

    const navItemTitle = (program: Program) => {
      if (programJoinRequests.value) {
        const record = programJoinRequests.value.find(
          (request) => request.programId === program.id
        );
        return record ? record.status : "none";
      }
    };

    const programLogo = (program: Program) => {
      if (program.logo) {
        return program.logo;
      } else {
        return fetchLogoFromWebsite(program.website);
      }
    };

    const fetchLogoFromWebsite = (websiteUrl: string): string => {
      const urlTemplate =
        "https://img.logo.dev/{domain}?token=pk_R1LW6BZBRPKs6t3nZphCOw";
      const placeholder = "{domain}";
      try {
        const url = new URL(websiteUrl);
        const domain = url.hostname.split(".").slice(-2).join(".");
        return urlTemplate.replace(placeholder, domain);
      } catch (error) {
        return urlTemplate.replace(placeholder, "www.logo.com");
      }
    };

    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    const setInvitedProgram = () => {
      if (authStore.joinProgram) {
        const invitedProgram = {
          id: authStore.joinProgram.id,
          name: authStore.joinProgram.name,
          experts: 0,
          website: authStore.joinProgram.website,
          logo: "",
          description: "",
          payout: 0,
          companyId: "",
        };

        if (invitedProgram) {
          programsStore.setSelectedProgram(invitedProgram);
          updateFocusedProgram(invitedProgram);
        }
        authStore.clearInvitedProgram();
        authStore.clearSelfEnrollmentProgram();
      }
    };

    const scrollTo = (direction: string) => {
      const nav = document.querySelector("#programsNavbar");
      if (nav) {
        const scrollAmount = 100;
        if (direction === "left") {
          nav.scrollBy({ left: -scrollAmount, behavior: "smooth" });
        } else if (direction === "right") {
          nav.scrollBy({ left: scrollAmount, behavior: "smooth" });
        }
      }
    };

    const disableEditProfile = computed(() => {
      if (selectedProfile.value && editInformation.value) {
        return false;
      } else if (selectedProfile.value && !editInformation.value) {
        return true;
      } else {
        return false;
      }
    });

    const checkNavbarScrollability = () => {
      const container = document.querySelector("#programsNavbar");
      if (container) {
        checkScrollability();
        container.addEventListener("scroll", checkScrollability);
      }
    };

    const checkScrollability = () => {
      const container = document.querySelector("#programsNavbar");
      if (container) {
        // checks if the user has scrolled past the start
        isScrolledLeft.value = container.scrollLeft > 0;

        // checks if the user has not yet scrolled to the end
        const scrollableWidth = container.scrollWidth - container.clientWidth;
        isScrolledRight.value =
          Math.ceil(container.scrollLeft) < scrollableWidth;
      }
    };

    const scrollLeft = () => {
      const container = document.querySelector("#programsNavbar");
      if (container) {
        container.scrollBy({ left: -200, behavior: "smooth" });
      }
    };

    const scrollRight = () => {
      const container = document.querySelector("#programsNavbar");
      if (container) {
        container.scrollBy({ left: 200, behavior: "smooth" });
      }
    };

    const expertiseValue = (value: number) => {
      if (value === 1) {
        return "Novice";
      } else if (value === 2) {
        return "Competent";
      } else if (value === 3) {
        return "Proficient";
      } else if (value === 4) {
        return "Master";
      } else if (value === 5) {
        return "Expert";
      }
    };

    const experienceTimeDuration = (value: number) => {
      if (value === 1) {
        return "<1 month";
      } else if (value === 2) {
        return "1-6 months";
      } else if (value === 3) {
        return "6-12 months";
      } else if (value === 4) {
        return "1-3 years";
      } else if (value === 5) {
        return "3+ years";
      }
    };

    const experienceUserType = (value: number) => {
      if (value === 1) {
        return "Beginner User";
      } else if (value === 2) {
        return "Active User";
      } else if (value === 3) {
        return "Advanced User";
      } else if (value === 4) {
        return "Super User";
      } else if (value === 5) {
        return "Expert User";
      }
    };

    const expertProgramNotAvailable = (program: Program | null) => {
      return program && !program.companyId;
    };

    const handleChildComponentError = (message: string) => {
      errorMessage.value = message;
      scrollToTop();
    };

    onBeforeUnmount(() => {
      const container = document.querySelector("#programsNavbar");
      if (container) {
        container.removeEventListener("scroll", checkScrollability);
      }
    });

    return {
      demo,
      navItemTitle,
      isVideoLoading,
      showCreateSoftwareModal,
      expertNotified,
      handleLoadStart,
      handleCanPlay,
      editInformation,
      enableEditing,
      isScrolledLeft,
      isScrolledRight,
      scrollLeft,
      scrollRight,
      selectedProgramJoinRequest,
      selectedProgram,
      isRecording,
      formattedDuration,
      handleChildComponentError,
      video,
      startRecording,
      stopRecording,
      uploadMessage,
      uploadProgress,
      showSubmissionLoader,
      programLogo,
      disableEditProfile,
      expertiseSliderValue,
      expertiseValue,
      experienceSliderValue,
      experienceTimeDuration,
      experienceUserType,
      authStore,
      showTooltip,
      navItems,
      getNavItemClass,
      isActive,
      scrollTo,
      isTextareaFocused,
      fileInput,
      videoUrl,
      isSubmitting,
      handleSubmit,
      videoDuration,
      thumbnailUrl,
      uploadDate,
      selectedVideoUrl,
      selectedFile,
      errorMessage,
      showJoinRequestSubmittedModal,
      formatDuration,
      handleNavItemClick,
      handleAddProgram,
      expertProgramNotAvailable,
      selectedProfile,
      showAddProgramModal,
      requestSubmittedForProgram,
      programsStore,
      isFetchingUserProfile,
      EARNINGS_ICON,
      ARROW_RIGHT_ICON,
      ARROW_LEFT_ICON,
      CLOSE_ICON,
      COMPANY_LOGO,
      DASHBOARD_ICON,
      EXPERTISE_ICON,
      APPROVED_ICON,
      REJECTED_ICON,
      CIRCLE_PLUS,
      CHECK_MARK,
      VIDEO_CAMERA_ICON,
      CIRCLE_EXCLAMATION,
      EARNINGS_ICON_CALIBRI,
    };
  },
});
</script>

<style scoped>
/* Hide scrollbar by default */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Show scrollbar on hover */
.scrollbar-hidden:hover::-webkit-scrollbar {
  display: block;
}

.scrollbar-hidden:hover {
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: auto; /* Firefox */
}

/* Customize scrollbar */
.scrollbar-hidden::-webkit-scrollbar {
  height: 2px; /* For horizontal scrollbar */
}

.scrollbar-hidden::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}

.scrollbar-hidden::-webkit-scrollbar-track {
  background: transparent;
}
</style>
