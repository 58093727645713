<template>
  <div
    class="flex flex-col bg-white rounded-md border border-gray-300 shadow-sm w-full max-w-full min-w-[19rem] max-w-[19rem] overflow-hidden"
  >
    <div class="flex items-center h-12 sm:h-14 md:h-16 lg:h-18">
      <!-- Logo image on the left -->
      <div
        class="flex-shrink-0 w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16 lg:w-18 lg:h-18"
      >
        <img :src="logo" alt="Logo" class="object-cover w-full h-full" />
      </div>

      <!-- Text and icon container on the right -->
      <div class="flex flex-col flex-1 px-2 sm:px-4 py-2 sm:py-2.5 space-y-2">
        <!-- Text content -->
        <div
          class="flex flex-row items-start sm:items-center sm:space-x-2 sm:space-y-0"
        >
          <div class="flex-1 text-xs sm:text-sm md:text-base lg:text-lg">
            <div
              class="font-medium text-gray-900 truncate text-left break-words"
            >
              {{ truncatedName }}
            </div>
          </div>
          <!-- Icon as a button -->
          <button
            v-if="!isAdded"
            @click="emitCardData"
            class="flex-shrink-0 text-primary-purple focus:outline-none"
          >
            <div v-html="CIRCLE_PLUS"></div>
          </button>
          <button
            v-else-if="
              !authStore.joinProgram || authStore.joinProgram.id !== id
            "
            class="flex-shrink-0 text-gray-600 focus:outline-none"
            @click="removeCard"
          >
            <div v-html="CLOSE_ICON_SM"></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import { CLOSE_ICON_SM, CIRCLE_PLUS } from "@/assets/svg/shared/svgConstants";
import { useAuthStore } from "@/stores/auth";

export default defineComponent({
  name: "SoftwareCard",
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    experts: {
      type: Number,
      required: true,
    },
    logo: {
      type: String,
      required: true,
    },
    website: {
      type: String,
      requried: true,
    },
    isAdded: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    emitCardData() {
      this.$emit("add-card", {
        id: this.id,
        name: this.name,
        experts: this.experts,
        logo: this.logo,
        website: this.website,
      });
    },
    removeCard() {
      this.$emit("remove-card", {
        id: this.id,
        name: this.name,
        experts: this.experts,
        logo: this.logo,
        website: this.website,
      });
    },
  },

  setup(props) {
    const authStore = useAuthStore();

    const screenWidth = ref(window.innerWidth);

    const updateScreenWidth = () => {
      screenWidth.value = window.innerWidth;
    };

    const truncatedName = computed(() => {
      let sliceLength = 18;
      if (screenWidth.value < 640) {
        sliceLength = 30;
      } else if (screenWidth.value < 1024) {
        sliceLength = 20;
      }
      return props.name.length > sliceLength
        ? props.name.slice(0, sliceLength) + "..."
        : props.name;
    });

    onMounted(() => {
      window.addEventListener("resize", updateScreenWidth);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateScreenWidth);
    });

    return {
      authStore,
      truncatedName,
      CIRCLE_PLUS,
      CLOSE_ICON_SM,
    };
  },
});
</script>
