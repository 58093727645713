export const spokenLanguages = [
  "Arabic, Algerian Spoken",
  "Arabic, Egyptian Spoken",
  "Awadhi",
  "Azerbaijani, South",
  "Bengali",
  "Bhojpuri",
  "Burmese",
  "Chinese, Gan",
  "Chinese, Hakka",
  "Chinese, Jinyu",
  "Chinese, Mandarin",
  "Chinese, Min Nan",
  "Chinese, Wu",
  "Chinese, Xiang",
  "Chinese, Yue",
  "Dutch",
  "English",
  "Farsi, Western",
  "French",
  "German, Standard",
  "Gujarati",
  "Hausa",
  "Hindi",
  "Italian",
  "Japanese",
  "Javanese",
  "Kannada",
  "Korean",
  "Maithili",
  "Malayalam",
  "Marathi",
  "Oriya",
  "Panjabi, Eastern",
  "Panjabi, Western",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Serbo-Croatian",
  "Sindhi",
  "Spanish",
  "Sunda",
  "Tamil",
  "Telugu",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Urdu",
  "Vietnamese",
  "Yoruba",
];
