<template>
  <div class="flex flex-col bg-white h-screen">
    <!-- Added h-screen -->
    <header class="flex flex-col w-full max-md:max-w-full">
      <OnboardingNavbar :auth-store="authStore" />
    </header>
    <main
      class="flex z-10 flex-col items-center px-3 justify-center flex-grow pt-20 w-full bg-white rounded-md max-md:max-w-full"
    >
      <div class="text-center">
        <div class="flex flex-col items-center gap-4">
          <div class="flex items-center justify-center">
            <img
              v-if="authStore?.joinProgram?.website"
              :src="logoFromWebsite(authStore?.joinProgram?.website)"
              alt="Logo"
              class="w-3/4 h-3/4 rounded-full border border-gray-300"
            />
          </div>
          <h1
            class="self-center text-3xl font-semibold leading-tight text-zinc-800 max-md:max-w-full"
          >
            <p>You have been invited to {{ authStore.joinProgram?.name }}</p>
            <p class="mt-3 text-base text-neutral-700 max-md:max-w-full">
              Start creating your profile
            </p>
          </h1>
        </div>
        <div class="flex flex-col mt-4">
          <BaseButton
            type="button"
            @click="goToProfileInformation"
            class="border border-gray-300 px-4 py-2 text-base text-white bg-violet-600 rounded-md"
          >
            Start
            <i class="fas fa-arrow-right shrink-0 w-5 aspect-square"></i>
          </BaseButton>
        </div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useAuthStore } from "@/stores/auth";
import OnboardingNavbar from "@/components/onboarding/OnboardingNavbar.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "TokenView",
  components: {
    OnboardingNavbar,
    BaseButton,
  },
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();

    const token = computed(() => authStore.token);
    const email = computed(() => authStore.email);

    const goToProfileInformation = () => {
      router.push({ name: "OnboardingProfileInformation" });
    };

    const logoFromWebsite = (websiteUrl: string): string => {
      const urlTemplate =
        "https://img.logo.dev/{domain}?token=pk_R1LW6BZBRPKs6t3nZphCOw";
      const placeholder = "{domain}";

      try {
        const url = new URL(websiteUrl);
        const domain = url.hostname.split(".").slice(-2).join(".");

        return urlTemplate.replace(placeholder, domain);
      } catch (error) {
        return urlTemplate.replace(placeholder, "www.logo.com");
      }
    };

    return {
      goToProfileInformation,
      authStore,
      token,
      email,
      logoFromWebsite,
    };
  },
});
</script>

<style scoped>
.token-view {
  padding: 20px;
  text-align: center;
}

.token-input {
  margin-top: 10px;
  padding: 10px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
