<template>
  <div class="flex flex-col" :class="containerClass">
    <div
      class="flex flex-col px-6 py-8 mx-auto w-full bg-white rounded border border-gray-300 border-solid max-md:px-5 max-md:mt-4"
    >
      <div class="flex flex-wrap gap-2 items-center justify-between">
        <div class="flex flex-wrap items-center">
          <img
            loading="lazy"
            :src="iconSrc"
            class="object-contain h-auto"
            :class="iconClass"
          />
          <h2
            class="text-xl tracking-wide leading-snug"
            :class="titleColorClass"
          >
            {{ title }}
          </h2>
        </div>
        <div
          v-if="isConnected"
          class="flex flex-row items-center gap-2 rounded-full px-2 py-1"
          :class="{
            'bg-green-500': isActive,
            'bg-red-500': !isActive,
          }"
        >
          <span class="text-white text-xs">
            {{ isActive ? "Connected" : "Disconnected" }}
          </span>
          <div
            v-html="LINK_ICON"
            class="w-3 h-3 text-white cursor-pointer"
          ></div>
        </div>
      </div>
      <div
        class="flex flex-row gap-2 w-full mt-2"
        v-if="isConnected && (calendarName || calendarEmail)"
      >
        <div class="flex flex-col shrink-0">
          <div
            class="flex items-center justify-center w-8 h-8 bg-primary-purple text-white rounded-full"
          >
            {{ initials() }}
          </div>
        </div>
        <div class="flex flex-col w-full text-left">
          <span
            v-if="calendarName"
            class="text-sm font-semibold tracking-wide leading-snug text-neutral-900"
            >{{ calendarName }}</span
          >
          <span v-if="calendarEmail" class="text-sm text-gray-500">{{
            calendarEmail
          }}</span>
        </div>
      </div>
      <div
        class="flex flex-col w-full"
        :class="{
          'mt-6': provider === 'google',
          'mt-8': provider === 'outlook',
        }"
      >
        <div class="flex flex-col" v-if="!isConnected">
          <h2
            class="text-xl font-semibold tracking-wide leading-snug text-neutral-900"
          >
            Connect {{ provider }} Calendar
          </h2>
          <p
            class="mt-2 text-sm tracking-wide leading-5 text-gray-500"
            :class="{ 'lg:whitespace-nowrap': provider === 'outlook' }"
          >
            Connect {{ provider }} Calendar and set your <br />
            availability accordingly
          </p>
        </div>
        <div
          class="flex flex-col text-left text-xl font-semibold tracking-wide leading-snug text-neutral-900"
          v-else
        >
          <span>{{ capitalize(provider) }} Calendar</span>
        </div>
      </div>
      <div>
        <BaseButton
          type="button"
          class="overflow-hidden self-stretch px-3.5 py-2.5 mt-6 w-full text-sm font-medium leading-none rounded-md shadow-sm"
          :class="{
            'text-indigo-700 bg-indigo-50': isConnected,
            'text-white bg-violet-600': !isConnected,
          }"
          @click="$emit('connect')"
        >
          <span>{{ buttonText(isConnected, isActive) }}</span>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { LINK_ICON } from "@/assets/svg/shared/svgConstants";
import BaseButton from "@/components/shared/BaseButton.vue";

export default defineComponent({
  name: "CalendarCard",
  components: { BaseButton },
  props: {
    provider: {
      type: String,
      required: true,
      validator: (value: string) => ["google", "outlook"].includes(value),
    },
    isConnected: {
      type: Boolean,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    calendarName: {
      type: String,
      required: true,
    },
    calendarEmail: {
      type: String,
      required: true,
    },
  },
  emits: ["connect"],
  setup(props) {
    const title = props.provider === "google" ? "Calendar" : "Outlook";
    const iconClass = props.provider === "google" ? "w-16" : "w-12 pb-2";
    const titleColorClass =
      props.provider === "google" ? "text-gray-700" : "text-outlook-blue";
    const containerClass =
      props.provider === "outlook"
        ? "max-md:ml-0 max-md:w-full overflow-hidden"
        : "overflow-hidden";
    const profileImage = ref<HTMLImageElement | null>(null);

    const handleImageError = () => {
      if (profileImage.value) {
        profileImage.value.style.display = "none";
      }
    };

    const iconSrc = computed(() =>
      props.provider === "google"
        ? new URL(
            "../../assets/onboarding/google-calendar-icon.png",
            import.meta.url
          ).href
        : new URL(
            "../../assets/onboarding/outlook-calendar-icon.png",
            import.meta.url
          ).href
    );

    const capitalize = (str: string) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const initials = () => {
      if (props.calendarName) {
        const firstInitial = props.calendarName.charAt(0).toUpperCase();
        const lastInitial = props.calendarName.charAt(1).toUpperCase();
        return `${firstInitial}${lastInitial}`;
      }
      return "";
    };

    const buttonText = (isConnected: boolean, isActive: boolean) => {
      if (isConnected) {
        return isActive ? "Switch Account" : "Reconnect Calendar";
      }
      return "Connect Now";
    };
    return {
      title,
      iconSrc,
      iconClass,
      titleColorClass,
      containerClass,
      profileImage,
      handleImageError,
      capitalize,
      initials,
      buttonText,
      LINK_ICON,
    };
  },
});
</script>
