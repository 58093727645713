<template>
  <main class="bg-white min-h-screen flex flex-col">
    <div class="flex flex-grow max-md:flex-col">
      <section class="flex flex-col w-1/2 justify-center max-md:w-full p-5">
        <div
          class="flex flex-col w-1/2 mx-auto justify-center max-md:mt-4 max-md:w-full"
        >
          <h1
            class="text-3xl font-semibold leading-9 text-center text-zinc-800"
          >
            Sign Up
          </h1>
          <p class="mt-3 text-base leading-6 text-center text-neutral-700">
            Create your account to get started
          </p>
          <div
            v-if="errorMessage"
            role="alert"
            class="flex gap-2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
          >
            <!-- Icon Container -->
            <div
              class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
            >
              <!-- Custom Image Icon -->
              <img
                src="../assets/shared/failure.svg"
                alt="Error Icon"
                class="w-4 h-4"
              />
            </div>
            <p>{{ errorMessage }}</p>
          </div>
          <form @submit.prevent="handleSubmit">
            <div class="flex gap-5 justify-between mt-8">
              <div class="flex flex-col flex-grow basis-1/2">
                <BaseLabel
                  forAttr="firstName"
                  text="First Name"
                  class="text-sm font-medium leading-5 text-zinc-800 text-left"
                />
                <BaseInput
                  type="text"
                  id="firstName"
                  v-model="firstName"
                  className="flex-grow px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full"
                  placeholder="John"
                  required
                />
              </div>
              <div class="flex flex-col flex-grow basis-1/2">
                <BaseLabel
                  forAttr="lastName"
                  text="Last Name"
                  class="text-sm font-medium leading-5 text-zinc-800 text-left"
                />
                <BaseInput
                  type="text"
                  id="lastName"
                  v-model="lastName"
                  className="flex-grow px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full"
                  placeholder="Doe"
                  required
                />
              </div>
            </div>
            <div class="mt-6 text-left">
              <BaseLabel
                forAttr="email"
                text="Email"
                class="text-sm font-medium leading-5 text-zinc-800"
              />
              <BaseInput
                id="email"
                v-model="email"
                type="email"
                placeholder="slashexpert@gmail.com"
                className="px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full"
                required
              />
            </div>
            <div class="mt-6 text-left">
              <BaseLabel
                forAttr="password"
                text="Set-up Password"
                class="text-sm font-medium leading-5 text-zinc-800"
              />
              <div class="relative">
                <BaseInput
                  :type="showPassword ? 'text' : 'password'"
                  id="password"
                  v-model="password"
                  className="px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full"
                  required
                />
                <button
                  type="button"
                  @click="togglePasswordVisibility"
                  class="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                  <i
                    :class="{
                      'fas fa-eye': showPassword,
                      'fas fa-eye-slash': !showPassword,
                    }"
                    :title="showPassword ? 'Hide password' : 'Show password'"
                    class="w-5 h-5 cursor-pointer"
                  ></i>
                </button>
              </div>
            </div>
            <PasswordStrength
              :password="password"
              :confirmPassword="confirmPassword"
            />
            <div class="mt-6 text-left">
              <BaseLabel
                forAttr="confirmPassword"
                text="Confirm Password"
                class="text-sm font-medium leading-5 text-zinc-800"
              />
              <div class="relative">
                <BaseInput
                  :type="showConfirmPassword ? 'text' : 'password'"
                  id="confirmPassword"
                  v-model="confirmPassword"
                  className="px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full"
                  required
                />
                <button
                  type="button"
                  @click="toggleConfirmPasswordVisibility"
                  class="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                  <i
                    :class="{
                      'fas fa-eye': showConfirmPassword,
                      'fas fa-eye-slash': !showConfirmPassword,
                    }"
                    :title="
                      showConfirmPassword ? 'Hide password' : 'Show password'
                    "
                    class="w-5 h-5 cursor-pointer"
                  ></i>
                </button>
              </div>
            </div>
            <div class="flex items-center mt-6 text-base font-medium leading-6">
              <CheckBox
                v-model="agreedToTerms"
                id="termsAgreement"
                base-classes="relative w-4 h-4 border border-gray-300 rounded cursor-pointer appearance-none"
                checked-classes="bg-violet-600 border-violet-600"
                unchecked-classes="bg-white border-violet-600"
                label-classes="text-gray-500 ml-2"
              >
                I've read and agree with
                <a
                  href="https://www.slashexperts.com/terms"
                  class="text-violet-600 ml-1"
                  >Terms & Conditions</a
                >
              </CheckBox>
            </div>

            <BaseButton
              type="submit"
              :className="buttonClasses"
              :disabled="disableButton"
            >
              Continue
            </BaseButton>
          </form>
          <p
            class="flex gap-1 justify-center px-16 mt-6 text-base font-medium leading-6 max-md:px-5"
          >
            <span class="text-gray-500">Already have an account?</span>
            <span
              @click="navigateToSignIn"
              class="font-medium text-violet-600 cursor-pointer"
              >Sign in</span
            >
          </p>
        </div>
      </section>
      <aside
        class="flex flex-col items-center justify-center ml-5 w-1/2 max-md:ml-0 max-md:w-full bg-primary-purple"
      >
        <div
          class="flex flex-col flex-grow w-full items-center mt-10 justify-start px-20"
        >
          <div class="flex w-full justify-center items-center">
            <!-- First Image -->
            <img
              src="../assets/shared/expert-signup-banner-header.svg"
              alt="Header Image"
              class="w-full h-auto max-w-full object-contain"
            />
          </div>

          <!-- Third Image, or Footer Image -->
          <div class="flex w-full justify-center mt-20 items-center">
            <img
              src="../assets/shared/expert-signup-banner-body.svg"
              alt="Body Image"
              class="w-full h-auto max-w-full object-contain"
            />
          </div>
        </div>
      </aside>
    </div>
  </main>
</template>

<script lang="ts">
import { computed, defineComponent, getCurrentInstance, ref } from "vue";
import axios, { AxiosError } from "axios";
import PasswordStrength from "@/components/PasswordStrength.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import BaseInput from "@/components/shared/BaseInput.vue";
import CheckBox from "@/components/shared/CheckBox.vue";
import BaseLabel from "@/components/shared/BaseLabel.vue";
import { PostHog } from "posthog-js";
import { useRouter, useRoute } from "vue-router";
import { ErrorResponse } from "@/types/interfaces";
import { useAuthStore } from "@/stores/auth";
import { invitedProgram, selfEnrollmentProgram } from "@/types/shared";
import { useExpertProgramsStore } from "@/stores/expertPrograms";

export default defineComponent({
  name: "SignUp",
  components: {
    PasswordStrength,
    BaseButton,
    BaseInput,
    CheckBox,
    BaseLabel,
  },
  setup() {
    const firstName = ref("");
    const lastName = ref("");
    const email = ref("");
    const password = ref("");
    const confirmPassword = ref("");
    const agreedToTerms = ref(false);
    const showPassword = ref(false);
    const showConfirmPassword = ref(false);
    const errorMessage = ref<string | null>(null);
    const isSubmitting = ref(false);
    const tempDisable = ref(true);

    const authStore = useAuthStore();
    const programsStore = useExpertProgramsStore();
    const router = useRouter();
    const route = useRoute();
    const invitedToken = route.query.token;
    const selfEnrollmentProgramCode = route.query.self_enrollment_program;
    const posthog = (getCurrentInstance()?.proxy as { $posthog: PostHog })
      ?.$posthog;

    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };

    const toggleConfirmPasswordVisibility = () => {
      showConfirmPassword.value = !showConfirmPassword.value;
    };

    const handleSubmit = async () => {
      isSubmitting.value = true;
      tempDisable.value = false;
      errorMessage.value = null;

      const validationFailure = validateForm();

      if (validationFailure) {
        errorMessage.value = validationFailure;
        isSubmitting.value = false;
        tempDisable.value = true;
        return;
      }

      try {
        const response = await handleRequest();
        if (response.data.token) {
          handleSignUpSuccess(
            response.data.token,
            response.data.invited_program,
            response.data.self_enrollment_program,
            response.data.uuid
          );
          await navigateToOnboarding();
        } else {
          errorMessage.value = "Something went wrong, please try again";
        }
      } catch (error) {
        console.error("Error during sign-up:", error);
        errorMessage.value = processErrorResponse(
          error as AxiosError<ErrorResponse>
        );
      } finally {
        isSubmitting.value = false;
        tempDisable.value = true;
      }
    };

    const validateForm = (): string => {
      return validate();
    };

    const handleRequest = async () => {
      const url = process.env.VUE_APP_SIGN_UP_URL;
      const data = {
        user: {
          email: email.value,
          password: password.value,
          first_name: firstName.value,
          last_name: lastName.value,
          password_confirmation: confirmPassword.value,
          invited_token: invitedToken,
          self_enrollment_program_code: selfEnrollmentProgramCode,
        },
      };

      return axios.post(url, data);
    };

    const notifyPosthog = () => {
      if (posthog) {
        if (invitedToken) {
          posthog.capture("Expert_User_Clicked_On_Invite_Link", {
            email: email.value,
          });
        } else {
          posthog.capture("Expert_User_Clicked_Signup", {
            email: email.value,
          });
        }
      }
    };

    const handleSignUpSuccess = (
      token: string,
      invitedProgram: invitedProgram | null,
      selfEnrollmentProgram: selfEnrollmentProgram | null,
      uuid: string
    ) => {
      authStore.clearStore();
      produktlyIdentifyUser(uuid, email.value);
      if (posthog) {
        posthog.identify(uuid, {
          email: email.value,
          expert_first: firstName.value,
          expert_last: lastName.value,
          expert_email: email.value,
          expert_user: true,
          company_user: false,
          attendee_user: false,
        });
        notifyPosthog();
      }
      setAuthStore(token, invitedProgram, selfEnrollmentProgram);
    };

    const produktlyIdentifyUser = (uuid: string, email: string) => {
      if (window.Produktly) {
        window.Produktly.identifyUser(uuid, {
          email: email,
        });
      }
    };

    const setAuthStore = (
      token: string,
      invitedProgram: invitedProgram | null,
      selfEnrollmentProgram: selfEnrollmentProgram | null
    ) => {
      authStore.setToken(token);
      authStore.setEmail(email.value);
      authStore.setFirstName(firstName.value);
      authStore.setLastName(lastName.value);
      if (invitedProgram) {
        authStore.setInvitedProgram(invitedProgram);
      }
      if (selfEnrollmentProgram) {
        authStore.setSelfEnrollmentProgram(selfEnrollmentProgram);
      }
      programsStore.clearStore();
    };

    const navigateToOnboarding = async () => {
      if (authStore.token) {
        checkInvitedProgram();
        if (authStore.joinProgram) {
          await router.push({ name: "Onboarding" });
        } else {
          await router.push({ name: "OnboardingProfileInformation" });
        }
      } else {
        throw new Error("Failed to set auth token");
      }
    };

    const processErrorResponse = (error: AxiosError<ErrorResponse>) => {
      if (error.response && error.response.data.errors) {
        return error.response.data.errors.join(" ");
      }
      return "An unexpected error occurred. Please retry.";
    };

    const validate = () => {
      if (!agreedToTerms.value) {
        return "Please agree to the terms and conditions";
      }

      if (!isPasswordValid.value) {
        return "Password must be 8 characters long, with at least one uppercase and lowercase letter";
      }

      return "";
    };

    const checkInvitedProgram = () => {
      if (authStore.invitedProgram && !invitedToken) {
        authStore.clearInvitedProgram();
      }
    };

    const navigateToSignIn = () => {
      router.push({
        name: "SignIn",
        query: {
          token: invitedToken,
          self_enrollment_program: selfEnrollmentProgramCode,
        },
      });
    };

    const isPasswordValid = computed(() => {
      const hasUpperCase = /[A-Z]/.test(password.value);
      const hasLowerCase = /[a-z]/.test(password.value);
      const hasMinLength = password.value.length >= 8;
      return hasUpperCase && hasLowerCase && hasMinLength;
    });

    const isDisabled = computed(() => {
      return (
        !(
          firstName.value.length > 0 &&
          lastName.value.length > 0 &&
          email.value.length > 0 &&
          isPasswordValid.value &&
          password.value.length > 0 &&
          confirmPassword.value.length > 0 &&
          agreedToTerms.value
        ) || isSubmitting.value
      );
    });

    const buttonClasses = computed(
      () =>
        `w-full px-4 py-2.5 mt-6 text-base font-medium leading-6 text-white whitespace-nowrap bg-violet-600 rounded-md shadow-sm max-md:px-5 ${
          isDisabled.value ? "bg-opacity-60" : ""
        }`
    );

    const disableButton = computed(() => {
      return !tempDisable.value;
    });

    return {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      agreedToTerms,
      showPassword,
      showConfirmPassword,
      togglePasswordVisibility,
      toggleConfirmPasswordVisibility,
      handleSubmit,
      isDisabled,
      buttonClasses,
      isPasswordValid,
      errorMessage,
      disableButton,
      tempDisable,
      validate,
      navigateToSignIn,
    };
  },
});
</script>
