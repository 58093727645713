<template>
  <div class="flex flex-col mt-4">
    <div
      class="flex flex-row justify-between max-w-[1000px] max-md:max-w-[550px] w-full"
    >
      <div class="flex flex-col">
        <h2>Edit Calendar</h2>
      </div>
      <div class="flex flex-col">
        <section class="mt-16 max-md:mt-10">
          <div class="flex gap-6 flex-row max-md:flex-col">
            <CalendarCard
              provider="google"
              :isActive="calendarActive"
              :isConnected="calendarProvider === 'google'"
              :calendarName="calendarName"
              :calendarEmail="calendarEmail"
              @connect="connectGoogleCalendar"
            />
            <CalendarCard
              provider="outlook"
              :isActive="calendarActive"
              :isConnected="calendarProvider === 'outlook'"
              :calendarName="calendarName"
              :calendarEmail="calendarEmail"
              @connect="connectOutlookCalendar"
            />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { LINK_ICON } from "@/assets/svg/shared/svgConstants";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import CalendarCard from "@/components/expert-profile/CalendarCard.vue";

const GET_GOOGLE_OAUTH_URL = gql`
  query googleoauth($syncFrom: String!) {
    googleoauth(syncFrom: $syncFrom) {
      authorizationUrl
      errors
    }
  }
`;

const GET_OUTLOOK_OAUTH_URL = gql`
  query outlookoauth($syncFrom: String!) {
    outlookoauth(syncFrom: $syncFrom) {
      authorizationUrl
      errors
    }
  }
`;

export default defineComponent({
  name: "CalendarDetails",
  components: {
    CalendarCard,
  },
  props: {
    calendarEmail: {
      type: String,
      required: true,
    },
    calendarName: {
      type: String,
      required: true,
    },
    calendarProvider: {
      type: String,
      required: true,
    },
    calendarActive: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["error"],
  setup(_, { emit }) {
    const googleOAuthUrl = ref("");
    const outlookOAuthUrl = ref("");

    const connectGoogleCalendar = () => {
      const { onResult: queryResult, onError: queryError } = useQuery(
        GET_GOOGLE_OAUTH_URL,
        {
          syncFrom: "profile",
        },
        {
          fetchPolicy: "network-only",
        }
      );

      queryResult((newValue) => {
        if (newValue) {
          if (newValue?.errors) {
            emit("error", newValue?.errors);
          } else {
            if (newValue?.data?.googleoauth?.errors.length > 0) {
              emit("error", newValue?.data?.googleoauth?.errors.join(". "));
            } else if (newValue?.data?.googleoauth?.authorizationUrl) {
              googleOAuthUrl.value =
                newValue?.data?.googleoauth?.authorizationUrl;
              window.location.href = googleOAuthUrl.value;
            }
          }
        }
      });

      queryError((newError) => {
        if (newError) {
          emit("error", newError);
        }
      });
    };

    const connectOutlookCalendar = () => {
      const { onResult: queryResult, onError: queryError } = useQuery(
        GET_OUTLOOK_OAUTH_URL,
        {
          syncFrom: "profile",
        },
        {
          fetchPolicy: "network-only",
        }
      );

      queryResult((result) => {
        if (result) {
          if (result?.errors) {
            emit("error", result?.errors);
          } else {
            if (result?.data?.outlookoauth?.errors.length > 0) {
              emit("error", result?.data?.outlookoauth?.errors.join(". "));
            } else if (result?.data?.outlookoauth?.authorizationUrl) {
              outlookOAuthUrl.value =
                result?.data?.outlookoauth?.authorizationUrl;
              window.location.href = outlookOAuthUrl.value;
            }
          }
        }
      });

      queryError((newError) => {
        if (newError) {
          emit("error", newError);
        }
      });
    };
    return {
      connectGoogleCalendar,
      connectOutlookCalendar,
      LINK_ICON,
    };
  },
});
</script>
