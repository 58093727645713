<template>
  <div
    class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
  >
    <!-- Spinner -->
    <div
      class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
    >
      <span class="text-white text-3xl font-semibold leading-9">
        Signing you in...
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { useAuthStore } from "@/stores/auth";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import { ProfileInformation } from "@/types/interfaces";
import { useRoute, useRouter } from "vue-router";
import { Card } from "@/types/onboarding/types";

const GET_USER_PROFILE = gql`
  query userProfile($id: ID) {
    userProfile(id: $id) {
      firstName
      lastName
      email
      profile_picture
      linkedin_profile
      title
      companyName
      country
      state
      industry
      spokenLanguages
      uuid
      onboardingStep
      calendarDetails {
        provider
      }
      programs {
        id
        name
        logo
        website
        experts
      }
    }
  }
`;

export default defineComponent({
  name: "SignInLanding",
  setup() {
    const authToken = ref<string | null>(null);
    const authStore = useAuthStore();
    const router = useRouter();
    const route = useRoute();
    const invitedToken = route.query.token;
    const selfEnrollmentProgramCode = route.query.self_enrollment_program;
    const onboardingStep = ref("");
    const onboardingSyncedCalendar = ref("");
    const onboardingProgramsAdded = ref<Card[]>([]);
    let profileInformation: ProfileInformation = {
      linkedinUrl: "",
      country: "",
      state: "",
      industry: "",
      profilePicture: "",
      companyName: "",
      title: "",
      spokenLanguages: [],
    };

    onMounted(() => {
      if (authStore.token) {
        fetchUserProfile();
      } else {
        handleError("No token found.");
      }
    });

    const produktlyIdentifyUserLogin = (uuid: string, email: string) => {
      if (window.Produktly) {
        window.Produktly.identifyUser(uuid, {
          email: email,
        });
      }
    };

    const fetchUserProfile = () => {
      const { onResult, onError } = useQuery(GET_USER_PROFILE, {
        id: null,
      });

      onResult((result) => {
        if (result) {
          if (result.data?.userProfile) {
            produktlyIdentifyUserLogin(
              result.data.userProfile.uuid,
              result.data.userProfile.email
            );
            authStore.setFirstName(result.data.userProfile.firstName);
            authStore.setLastName(result.data.userProfile.lastName);
            authStore.setEmail(result.data.userProfile.email);
            profileInformation.profilePicture =
              result.data.userProfile.profile_picture;
            authStore.setProfileInformation(profileInformation);

            onboardingStep.value = result.data.userProfile.onboardingStep;
            onboardingSyncedCalendar.value =
              result.data.userProfile.calendarDetails?.provider || "";
            onboardingProgramsAdded.value = result.data.userProfile.programs;

            if (onboardingStep.value !== "completed") {
              profileInformation.linkedinUrl =
                result.data.userProfile.linkedin_profile;
              profileInformation.country = result.data.userProfile.country;
              profileInformation.state = result.data.userProfile.state;
              profileInformation.industry = result.data.userProfile.industry;
              profileInformation.companyName =
                result.data.userProfile.companyName;
              profileInformation.title = result.data.userProfile.title;
              profileInformation.spokenLanguages =
                result.data.userProfile.spokenLanguages;
            }
            detereminRouteBasedOnOnboardingStatus();
          }
        }
      });

      onError((error) => {
        console.error(error);
        if (error) {
          handleError(error.message);
        }
      });
    };

    const detereminRouteBasedOnOnboardingStatus = () => {
      if (onboardingStep.value === "completed") {
        navigateToExpertiseOrDashboard();
      } else {
        restoreAddedPrograms();
        restoreProfileInformation();
        if (onboardingStep.value === "availability") {
          authStore.setCalendarSynced(onboardingSyncedCalendar.value);
          router.push({ name: "OnboardingCalendar" });
        } else if (onboardingStep.value === "calendar") {
          router.push({ name: "OnboardingCalendar" });
        } else if (onboardingStep.value === "expertise") {
          router.push({ name: "OnboardingSoftwareExpertise" });
        } else {
          router.push({ name: "OnboardingProfileInformation" });
        }
      }
    };

    const restoreAddedPrograms = () => {
      if (onboardingProgramsAdded.value.length > 0) {
        authStore.setProgramsLinked(onboardingProgramsAdded.value);
        authStore.setSoftwareExpertise(
          onboardingProgramsAdded.value.map((program) => program.id)
        );
      }
    };

    const restoreProfileInformation = () => {
      if (profileInformation) {
        authStore.setProfileInformation(profileInformation);
      }
    };

    const checkInvitedProgram = () => {
      if (authStore.invitedProgram && !invitedToken) {
        authStore.clearInvitedProgram();
      }
    };

    const checkSelfEnrollmentProgram = () => {
      if (authStore.selfEnrollmentProgram && !selfEnrollmentProgramCode) {
        authStore.clearSelfEnrollmentProgram();
      }
    };

    const navigateToExpertiseOrDashboard = () => {
      if (authStore.token) {
        checkInvitedProgram();
        checkSelfEnrollmentProgram();
        if (invitedToken || selfEnrollmentProgramCode) {
          router.push({
            name: "ExpertExpertise",
          });
        } else {
          router.push({ name: "ExpertDashboard" });
        }
      } else {
        handleError("Failed to set auth token");
      }
    };

    const handleError = (error: string) => {
      const landingUrl = `${
        process.env.VUE_APP_SIGN_IN_FORM_URL
      }?error=${encodeURIComponent(error)}`;
      window.open(landingUrl, "_self");
    };

    return {
      authToken,
    };
  },
});
</script>
