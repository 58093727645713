<template>
  <div class="relative w-full baseSearchableDropdown">
    <div class="relative">
      <div
        class="p-2 border border-gray-300 rounded-md text-sm cursor-pointer"
        :class="{ 'opacity-50': disabled }"
        @click="toggleDropdown"
      >
        <span>{{ selectedValue || placeholder }}</span>
      </div>

      <div
        v-if="isOpen"
        class="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-md w-full"
      >
        <input
          v-if="searchable"
          type="text"
          v-model="searchQuery"
          :placeholder="searchPlaceholder"
          class="p-2 border-b border-gray-200 text-sm w-full"
        />

        <ul class="max-h-40 overflow-auto">
          <li
            v-for="(option, index) in filteredOptions"
            :key="index"
            @click="selectOption(option)"
            class="p-2 hover:bg-gray-100 border-b border-gray-200 cursor-pointer"
          >
            {{ option }}
          </li>
          <li v-if="filteredOptions.length === 0" class="p-2 text-gray-500">
            {{ noOptionsText }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  onUnmounted,
} from "vue";

export default defineComponent({
  name: "BaseDropdown",
  props: {
    options: {
      type: Array as () => string[],
      required: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Select an option",
    },
    searchPlaceholder: {
      type: String,
      default: "Search...",
    },
    noOptionsText: {
      type: String,
      default: "No options found",
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "change"],
  setup(props, { emit }) {
    const isOpen = ref(false);
    const searchQuery = ref("");
    const selectedValue = computed(() => props.modelValue);

    const filteredOptions = computed(() => {
      const filtered = props.options.filter((option) =>
        option.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
      console.log("Filtered Options:", {
        searchQuery: searchQuery.value,
        results: filtered,
      });
      return filtered;
    });

    const toggleDropdown = () => {
      if (!props.disabled) {
        isOpen.value = !isOpen.value;
        if (!isOpen.value) {
          searchQuery.value = "";
        }
      }
    };

    const selectOption = (option: string) => {
      emit("update:modelValue", option);
      emit("change", option);
      isOpen.value = false;
      searchQuery.value = "";
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpen.value &&
        !(event.target as Element).closest(".baseSearchableDropdown")
      ) {
        isOpen.value = false;
        searchQuery.value = "";
      }
    };

    watch(
      () => props.modelValue,
      (newValue) => {
        if (newValue === "") {
          searchQuery.value = "";
        }
      }
    );

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    return {
      isOpen,
      searchQuery,
      selectedValue,
      filteredOptions,
      toggleDropdown,
      selectOption,
    };
  },
});
</script>
