import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative w-full baseSearchableDropdown" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = {
  key: 0,
  class: "absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-md w-full"
}
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "max-h-40 overflow-auto" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  class: "p-2 text-gray-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["p-2 border border-gray-300 rounded-md text-sm cursor-pointer", { 'opacity-50': _ctx.disabled }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.selectedValue || _ctx.placeholder), 1)
      ], 2),
      (_ctx.isOpen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.searchable)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 0,
                  type: "text",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchQuery) = $event)),
                  placeholder: _ctx.searchPlaceholder,
                  class: "p-2 border-b border-gray-200 text-sm w-full"
                }, null, 8, _hoisted_4)), [
                  [_vModelText, _ctx.searchQuery]
                ])
              : _createCommentVNode("", true),
            _createElementVNode("ul", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, (option, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  onClick: ($event: any) => (_ctx.selectOption(option)),
                  class: "p-2 hover:bg-gray-100 border-b border-gray-200 cursor-pointer"
                }, _toDisplayString(option), 9, _hoisted_6))
              }), 128)),
              (_ctx.filteredOptions.length === 0)
                ? (_openBlock(), _createElementBlock("li", _hoisted_7, _toDisplayString(_ctx.noOptionsText), 1))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}