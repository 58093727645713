export const industries: string[] = [
  "Abrasives and Nonmetallic Minerals Manufacturing",
  "Accommodation Services",
  "Accounting",
  "Administration of Justice",
  "Administrative and Support Services",
  "Advertising Services",
  "Agricultural Chemical Manufacturing",
  "Agriculture, Construction, Mining Machinery Manufacturing",
  "Air, Water, and Waste Program Management",
  "Airlines and Aviation",
  "Alternative Dispute Resolution",
  "Alternative Medicine",
  "Ambulance Services",
  "Amusement Parks and Arcades",
  "Animal Feed Manufacturing",
  "Animation",
  "Animation and Post-production",
  "Apparel Manufacturing",
  "Apparel and Fashion",
  "Appliances, Electrical, and Electronics Manufacturing",
  "Architectural and Structural Metal Manufacturing",
  "Architecture and Planning",
  "Armed Forces",
  "Artificial Rubber and Synthetic Fiber Manufacturing",
  "Artists and Writers",
  "Arts and Crafts",
  "Audio and Video Equipment Manufacturing",
  "Automation Machinery Manufacturing",
  "Automotive",
  "Aviation & Aerospace",
  "Aviation and Aerospace Component Manufacturing",
  "Baked Goods Manufacturing",
  "Banking",
  "Bars, Taverns, and Nightclubs",
  "Bed-and-Breakfasts, Hostels, Homestays",
  "Beverage Manufacturing",
  "Biomass Electric Power Generation",
  "Biotechnology",
  "Blockchain Services",
  "Blogs",
  "Boilers, Tanks, and Shipping Container Manufacturing",
  "Book Publishing",
  "Book and Periodical Publishing",
  "Breweries",
  "Broadcast Media Production and Distribution",
  "Building Construction",
  "Building Equipment Contractors",
  "Building Finishing Contractors",
  "Building Materials",
  "Building Structure and Exterior Contractors",
  "Business Consulting and Services",
  "Business Content",
  "Business Intelligence Platforms",
  "Business Supplies and Equipment",
  "Cable and Satellite Programming",
  "Capital Markets",
  "Caterers",
  "Chemical Manufacturing",
  "Chemical Raw Materials Manufacturing",
  "Child Day Care Services",
  "Chiropractors",
  "Circuses and Magic Shows",
  "Civic and Social Organizations",
  "Civil Engineering",
  "Claims Adjusting, Actuarial Services",
  "Clay and Refractory Products Manufacturing",
  "Coal Mining",
  "Collection Agencies",
  "Commercial Real Estate",
  "Commercial and Industrial Equipment Rental",
  "Commercial and Industrial Machinery Maintenance",
  "Commercial and Service Industry Machinery Manufacturing",
  "Communications Equipment Manufacturing",
  "Community Development and Urban Planning",
  "Community Services",
  "Computer Games",
  "Computer Hardware",
  "Computer Hardware Manufacturing",
  "Computer Networking",
  "Computer Networking Products",
  "Computer and Network Security",
  "Computers and Electronics Manufacturing",
  "Conservation Programs",
  "Construction",
  "Construction Hardware Manufacturing",
  "Consumer Electronics",
  "Consumer Goods",
  "Consumer Goods Rental",
  "Consumer Services",
  "Correctional Institutions",
  "Cosmetics",
  "Cosmetology and Barber Schools",
  "Courts of Law",
  "Credit Intermediation",
  "Cutlery and Handtool Manufacturing",
  "Dairy",
  "Dairy Product Manufacturing",
  "Dance Companies",
  "Data Infrastructure and Analytics",
  "Data Security Software Products",
  "Defense & Space",
  "Defense and Space Manufacturing",
  "Dentists",
  "Design",
  "Design Services",
  "Desktop Computing Software Products",
  "Distilleries",
  "E-Learning",
  "E-Learning Providers",
  "Economic Programs",
  "Education",
  "Education Administration Programs",
  "Education Management",
  "Electric Lighting Equipment Manufacturing",
  "Electric Power Generation",
  "Electric Power Transmission, Control, and Distribution",
  "Electrical Equipment Manufacturing",
  "Electronic and Precision Equipment Maintenance",
  "Embedded Software Products",
  "Emergency and Relief Services",
  "Engineering Services",
  "Engines and Power Transmission Equipment Manufacturing",
  "Entertainment",
  "Entertainment Providers",
  "Environmental Quality Programs",
  "Environmental Services",
  "Equipment Rental Services",
  "Events Services",
  "Executive Offices",
  "Executive Search Services",
  "Fabricated Metal Products",
  "Facilities Services",
  "Family Planning Centers",
  "Farming",
  "Farming, Ranching, Forestry",
  "Fashion Accessories Manufacturing",
  "Financial Services",
  "Fine Art",
  "Fine Arts Schools",
  "Fire Protection",
  "Fisheries",
  "Flight Training",
  "Food Production",
  "Food and Beverage Manufacturing",
  "Food and Beverage Retail",
  "Food and Beverage Services",
  "Footwear Manufacturing",
  "Footwear and Leather Goods Repair",
  "Forestry and Logging",
  "Fossil Fuel Electric Power Generation",
  "Freight and Package Transportation",
  "Fruit and Vegetable Preserves Manufacturing",
  "Fundraising",
  "Funds and Trusts",
  "Furniture",
  "Furniture and Home Furnishings Manufacturing",
  "Gambling Facilities and Casinos",
  "Geothermal Electric Power Generation",
  "Glass Product Manufacturing",
  "Glass, Ceramics and Concrete Manufacturing",
  "Golf Courses and Country Clubs",
  "Government Administration",
  "Government Relations",
  "Government Relations Services",
  "Graphic Design",
  "Ground Passenger Transportation",
  "HVAC and Refrigeration Equipment Manufacturing",
  "Health and Human Services",
  "Health, Wellness and Fitness",
  "Higher Education",
  "Highway, Street, and Bridge Construction",
  "Historical Sites",
  "Holding Companies",
  "Home Health Care Services",
  "Horticulture",
  "Hospitality",
  "Hospitals",
  "Hospitals and Health Care",
  "Hotels and Motels",
  "Household Appliance Manufacturing",
  "Household Services",
  "Household and Institutional Furniture Manufacturing",
  "Housing Programs",
  "Housing and Community Development",
  "Human Resources",
  "Human Resources Services",
  "Hydroelectric Power Generation",
  "IT Services and IT Consulting",
  "IT System Custom Software Development",
  "IT System Data Services",
  "IT System Design Services",
  "IT System Installation and Disposal",
  "IT System Operations and Maintenance",
  "IT System Testing and Evaluation",
  "IT System Training and Support",
  "Import and Export",
  "Individual and Family Services",
  "Industrial Automation",
  "Industrial Machinery Manufacturing",
  "Industry Associations",
  "Information Services",
  "Information Technology and Services",
  "Insurance",
  "Insurance Agencies and Brokerages",
  "Insurance Carriers",
  "Insurance and Employee Benefit Funds",
  "Interior Design",
  "International Affairs",
  "International Trade and Development",
  "Internet Marketplace Platforms",
  "Internet News",
  "Internet Publishing",
  "Interurban and Rural Bus Services",
  "Investment Advice",
  "Investment Banking",
  "Investment Management",
  "Janitorial Services",
  "Landscaping Services",
  "Language Schools",
  "Laundry and Drycleaning Services",
  "Law Enforcement",
  "Law Practice",
  "Leasing Non-residential Real Estate",
  "Leasing Real Estate Agents and Brokers",
  "Leasing Residential Real Estate",
  "Leather Product Manufacturing",
  "Legal Services",
  "Legislative Offices",
  "Leisure, Travel & Tourism",
  "Libraries",
  "Lime and Gypsum Products Manufacturing",
  "Loan Brokers",
  "Machinery Manufacturing",
  "Magnetic and Optical Media Manufacturing",
  "Manufacturing",
  "Maritime",
  "Maritime Transportation",
  "Market Research",
  "Marketing Services",
  "Mattress and Blinds Manufacturing",
  "Measuring and Control Instrument Manufacturing",
  "Meat Products Manufacturing",
  "Mechanical or Industrial Engineering",
  "Media Production",
  "Media and Telecommunications",
  "Medical Devices",
  "Medical Equipment Manufacturing",
  "Medical Practices",
  "Medical and Diagnostic Laboratories",
  "Mental Health Care",
  "Metal Ore Mining",
  "Metal Treatments",
  "Metal Valve, Ball, and Roller Manufacturing",
  "Metalworking Machinery Manufacturing",
  "Military and International Affairs",
  "Mining",
  "Mobile Computing Software Products",
  "Mobile Food Services",
  "Mobile Gaming Apps",
  "Motor Vehicle Manufacturing",
  "Motor Vehicle Parts Manufacturing",
  "Movies and Sound Recording",
  "Movies, Videos and Sound",
  "Museums",
  "Museums, Historical Sites, and Zoos",
  "Music",
  "Musicians",
  "Nanotechnology Research",
  "Natural Gas Distribution",
  "Natural Gas Extraction",
  "Newspaper Publishing",
  "Non-profit Organization Management",
  "Non-profit Organizations",
  "Nonmetallic Mineral Mining",
  "Nonresidential Building Construction",
  "Nuclear Electric Power Generation",
  "Nursing Homes and Residential Care Facilities",
  "Office Administration",
  "Office Furniture and Fixtures Manufacturing",
  "Oil Extraction",
  "Oil and Coal Product Manufacturing",
  "Oil and Gas",
  "Oil, Gas, and Mining",
  "Online Audio and Video Media",
  "Online Media",
  "Online and Mail Order Retail",
  "Operations Consulting",
  "Optometrists",
  "Outpatient Care Centers",
  "Outsourcing and Offshoring Consulting",
  "Outsourcing/Offshoring",
  "Packaging and Containers",
  "Packaging and Containers Manufacturing",
  "Paint, Coating, and Adhesive Manufacturing",
  "Paper and Forest Product Manufacturing",
  "Paper and Forest Products",
  "Pension Funds",
  "Performing Arts",
  "Performing Arts and Spectator Sports",
  "Periodical Publishing",
  "Personal Care Product Manufacturing",
  "Personal Care Services",
  "Personal and Laundry Services",
  "Pet Services",
  "Pharmaceutical Manufacturing",
  "Philanthropic Fundraising Services",
  "Philanthropy",
  "Photography",
  "Physical, Occupational and Speech Therapists",
  "Physicians",
  "Pipeline Transportation",
  "Plastics Manufacturing",
  "Plastics and Rubber Product Manufacturing",
  "Political Organizations",
  "Postal Services",
  "Primary Metal Manufacturing",
  "Primary and Secondary Education",
  "Printing Services",
  "Professional Organizations",
  "Professional Services",
  "Professional Training and Coaching",
  "Program Development",
  "Public Assistance Programs",
  "Public Health",
  "Public Policy",
  "Public Policy Offices",
  "Public Relations and Communications Services",
  "Public Safety",
  "Racetracks",
  "Radio and Television Broadcasting",
  "Rail Transportation",
  "Railroad Equipment Manufacturing",
  "Ranching",
  "Ranching and Fisheries",
  "Real Estate",
  "Real Estate and Equipment Rental Services",
  "Recreational Facilities",
  "Religious Institutions",
  "Renewable Energy Equipment Manufacturing",
  "Renewable Energy Power Generation",
  "Renewable Energy Semiconductor Manufacturing",
  "Renewables & Environment",
  "Repair and Maintenance",
  "Research",
  "Research Services",
  "Residential Building Construction",
  "Restaurants",
  "Retail",
  "Retail Apparel and Fashion",
  "Retail Appliances, Electrical, and Electronic Equipment",
  "Retail Art Dealers",
  "Retail Art Supplies",
  "Retail Books and Printed News",
  "Retail Building Materials and Garden Equipment",
  "Retail Florists",
  "Retail Furniture and Home Furnishings",
  "Retail Gasoline",
  "Retail Groceries",
  "Retail Health and Personal Care Products",
  "Retail Luxury Goods and Jewelry",
  "Retail Motor Vehicles",
  "Retail Musical Instruments",
  "Retail Office Equipment",
  "Retail Office Supplies and Gifts",
  "Retail Recyclable Materials & Used Merchandise",
  "Reupholstery and Furniture Repair",
  "Rubber Products Manufacturing",
  "Satellite Telecommunications",
  "Savings Institutions",
  "School and Employee Bus Services",
  "Seafood Product Manufacturing",
  "Secretarial Schools",
  "Securities and Commodity Exchanges",
  "Security Guards and Patrol Services",
  "Security Systems Services",
  "Security and Investigations",
  "Semiconductor Manufacturing",
  "Semiconductors",
  "Services for Renewable Energy",
  "Services for the Elderly and Disabled",
  "Sheet Music Publishing",
  "Shipbuilding",
  "Shuttles and Special Needs Transportation Services",
  "Sightseeing Transportation",
  "Skiing Facilities",
  "Soap and Cleaning Product Manufacturing",
  "Social Networking Platforms",
  "Software Development",
  "Solar Electric Power Generation",
  "Sound Recording",
  "Space Research and Technology",
  "Specialty Trade Contractors",
  "Spectator Sports",
  "Sporting Goods",
  "Sporting Goods Manufacturing",
  "Sports Teams and Clubs",
  "Sports and Recreation Instruction",
  "Spring and Wire Product Manufacturing",
  "Staffing and Recruiting",
  "Steam and Air-Conditioning Supply",
  "Strategic Management Services",
  "Subdivision of Land",
  "Sugar and Confectionery Product Manufacturing",
  "Taxi and Limousine Services",
  "Technical and Vocational Training",
  "Technology, Information and Internet",
  "Technology, Information and Media",
  "Telecommunications",
  "Telecommunications Carriers",
  "Telephone Call Centers",
  "Temporary Help Services",
  "Textile Manufacturing",
  "Theater Companies",
  "Think Tanks",
  "Tobacco",
  "Tobacco Manufacturing",
  "Translation and Localization",
  "Transportation Equipment Manufacturing",
  "Transportation Programs",
  "Transportation, Logistics, Supply Chain and Storage",
  "Transportation/Trucking/Railroad",
  "Travel Arrangements",
  "Truck Transportation",
  "Trusts and Estates",
  "Turned Products and Fastener Manufacturing",
  "Urban Transit Services",
  "Utilities",
  "Utilities Administration",
  "Utility System Construction",
  "Vehicle Repair and Maintenance",
  "Venture Capital and Private Equity Principals",
  "Veterinary",
  "Veterinary Services",
  "Vocational Rehabilitation Services",
  "Warehousing",
  "Warehousing and Storage",
  "Waste Collection",
  "Waste Treatment and Disposal",
  "Water Supply and Irrigation Systems",
  "Water, Waste, Steam, and Air Conditioning Services",
  "Wellness and Fitness Services",
  "Wholesale",
  "Wholesale Alcoholic Beverages",
  "Wholesale Apparel and Sewing Supplies",
  "Wholesale Appliances, Electrical, and Electronics",
  "Wholesale Building Materials",
  "Wholesale Chemical and Allied Products",
  "Wholesale Computer Equipment",
  "Wholesale Drugs and Sundries",
  "Wholesale Food and Beverage",
  "Wholesale Footwear",
  "Wholesale Furniture and Home Furnishings",
  "Wholesale Hardware, Plumbing, Heating Equipment",
  "Wholesale Import and Export",
  "Wholesale Luxury Goods and Jewelry",
  "Wholesale Machinery",
  "Wholesale Metals and Minerals",
  "Wholesale Motor Vehicles and Parts",
  "Wholesale Paper Products",
  "Wholesale Petroleum and Petroleum Products",
  "Wholesale Photography Equipment and Supplies",
  "Wholesale Raw Farm Products",
  "Wholesale Recyclable Materials",
  "Wind Electric Power Generation",
  "Wine and Spirits",
  "Wineries",
  "Wireless Services",
  "Women's Handbag Manufacturing",
  "Wood Product Manufacturing",
  "Writing and Editing",
  "Zoos and Botanical Gardens",
];
